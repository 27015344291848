import { useEffect } from 'react';
import useAppSelector from '../hooks/use-app-selector';
import { Coupon } from '../types/coupon';
import { useAccountInfoQuery } from '../services/setting';

export const emptyFn = () => {};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatCurrency = (amount: number, currency = 'JPY') =>
  amount.toLocaleString('ja-JP', {
    style: 'currency',
    currency,
  });

export const formatFractionCurrency = (amount: number, currency = 'JPY') =>
  amount.toLocaleString('ja-JP', {
    style: 'currency',
    currency,
    maximumFractionDigits: 6,
    // The options is too new and ts is not supported
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roundingPriority: 'morePrecision',
  });

export const formatDateFull = (dateString: string, locale = 'ja') => {
  return new Date(dateString).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
};

// Coupon related helpers

export const getCouponCondition = (coupon: Coupon) => {
  if (coupon.discountType === 'amount') {
    const discountAmount = formatCurrency(
      coupon?.discountAmount ?? 0,
      coupon.currency
    );

    if (coupon.expiresAt) {
      return `${discountAmount}の割引`;
    }

    return `${discountAmount}の割引を無期限に適用`;
  }

  if (coupon.discountType === 'percentage') {
    const discountPercentage = coupon?.discountPercentage ?? 0;

    if (coupon.expiresAt) {
      return `${discountPercentage}%の割引`;
    }

    return `${discountPercentage}%の割引を無期限に適用`;
  }

  return '';
};

export const isReachAmountLimit = (coupon: {
  redemptionCount: number;
  maxRedemptionCount?: number;
}) => {
  if (!coupon?.maxRedemptionCount) {
    return false;
  }

  return coupon.redemptionCount >= coupon?.maxRedemptionCount;
};

export const isReachedDateLimit = (coupon: { expiresAt?: number | null }) => {
  const expiryDate = coupon.expiresAt ? new Date(coupon.expiresAt) : null;

  return expiryDate ? expiryDate < new Date() : false;
};

export const couponDetails = (coupon: {
  redemptionCount: number;
  maxRedemptionCount?: number;
  expiresAt?: number | null;
  createdAt: number;
}) => {
  const isFinished = isReachAmountLimit(coupon);
  const isExpired = isReachedDateLimit(coupon);
  const createdDate = new Date(coupon.createdAt);
  const expiryDate = coupon.expiresAt ? new Date(coupon.expiresAt) : null;

  return {
    isFinished,
    isExpired,
    isEnded: isFinished || isExpired,
    createdDate,
    expiryDate,
  };
};

export const useAccountStatus = (): {
  test: boolean;
  active: boolean;
} => {
  const { data: accountInfo } = useAccountInfoQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const accountStatus = accountInfo?.status;

  return {
    test: accountStatus === 'test',
    active: accountStatus === 'active',
  };
};

export const useDataMode = (): { test: boolean } => {
  const dataMode = useAppSelector((state) => state.settingAccounts.dataMode);

  return {
    test: dataMode === 'test',
  };
};

export const useDataModeChanged = ({
  id,
  onDataModeChanged = emptyFn,
}: {
  id: string;
  onDataModeChanged?: () => void;
}): void => {
  const isTestPage = id.includes('_test_');
  const { test: isTestDataMode } = useDataMode();
  let isDataModeChanged = false;

  if (isTestPage && !isTestDataMode) {
    isDataModeChanged = true;
  } else if (!isTestPage && isTestDataMode) {
    isDataModeChanged = true;
  }

  useEffect(() => {
    if (isDataModeChanged) {
      onDataModeChanged();
    }
  }, [isDataModeChanged, onDataModeChanged]);
};
