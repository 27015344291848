import Mixpanel from '@smartpay/mixpanel';
import { useState } from 'react';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import styles from './APIKeyInput.module.scss';

const APIKeyInput = ({
  label = '',
  name = '',
  masked = false,
  value = '',
  maskable = false,
  itemName = '',
}: {
  label?: string;
  name?: string;
  masked?: boolean;
  maskable?: boolean;
  value?: string;
  itemName?: string;
}) => {
  const [isMasked, setIsMasked] = useState(masked);

  return (
    <div className={styles.container}>
      <TextInput
        name={name}
        label={label}
        type="text"
        variant="secondary"
        value={value}
        maskedContent={isMasked}
        rightIcon="copy"
        onRightIconClick={() => {
          Mixpanel.trackAction({
            action: 'Click',
            itemName: `Copy ${itemName}`,
            props: {
              label,
              name,
            },
          });
        }}
        readOnly
      />
      {maskable && (
        <Button
          label={isMasked ? 'Reveal' : 'Hide'}
          variant="outline"
          className={styles['mask-btn']}
          onClick={() => {
            Mixpanel.trackAction({
              action: 'Click',
              itemName: `${isMasked ? 'Reveal' : 'Hide'} ${itemName}`,
              props: {
                label,
                name,
              },
            });
            setIsMasked(!isMasked);
          }}
        />
      )}
    </div>
  );
};

export default APIKeyInput;
