export const ERROR_REQUIRED_FIELD = 'ERROR_REQUIRED_FIELD';
export const ERROR_PASSWORD_NOT_SECURE = 'ERROR_PASSWORD_NOT_SECURE';
export const ERROR_PASSWORD_NOT_MATCH = 'ERROR_PASSWORD_NOT_MATCH';
export const ERROR_OTP_NOT_MATCH = 'ERROR_OTP_NOT_MATCH';

export const ERROR_RESOURCE_NOT_FOUND = 'ERROR_RESOURCE_NOT_FOUND';
export const ERROR_REQUEST_INVALID = 'ERROR_REQUEST_INVALID';
export const ERROR_NOT_AUTHORIZED = 'ERROR_NOT_AUTHORIZED';
export const ERROR_UNEXPECTED_ERROR = 'ERROR_UNEXPECTED_ERROR';

export const ERROR_PAYMENT_NOTFOUND = 'payment.notfound';
export const ERROR_AUTHORIZATION_FAILURE = 'authorization.failure';
export const ERROR_AMOUNT_INSUFFICIENT = 'amount.insufficient';
export const ERROR_SERVICE_EXCEPTION = 'service.exception';

export const ERROR_PROMOTION_CODE_NOTFOUND = 'promotioncode.notfound';
export const ERROR_PROMOTION_CODE_EXISTS = 'promotion-code.code-exists';
