import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import couponAPI, {
  CouponParams,
  CreatePromotionCodeParams,
  UpdateCouponParams,
} from '../api/coupon';
import { ErrorDetail } from './auth';
import {
  ERROR_AUTHORIZATION_FAILURE,
  ERROR_PROMOTION_CODE_EXISTS,
  ERROR_PROMOTION_CODE_NOTFOUND,
  ERROR_UNEXPECTED_ERROR,
} from './error-codes';

const initialState: {} = {};

type CreateCouponResponse = {};

type GeneralAsyncThunkConfig = {
  rejectValue: {
    errorCode: string;
    message?: string;
    details?: ErrorDetail[];
  };
};

export const createCoupon = createAsyncThunk<
  CreateCouponResponse,
  CouponParams,
  GeneralAsyncThunkConfig
>('coupons/createCoupon', async (params: CouponParams, { rejectWithValue }) => {
  try {
    const response = await couponAPI.createCoupon(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { data: payload } = error.response;

      switch (payload.errorCode) {
        case ERROR_AUTHORIZATION_FAILURE:
        case ERROR_UNEXPECTED_ERROR: {
          return rejectWithValue(payload);
        }
        default: {
          break;
        }
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

type UpdateCouponResponse = {};

export const updateCoupon = createAsyncThunk<
  UpdateCouponResponse,
  UpdateCouponParams,
  GeneralAsyncThunkConfig
>(
  'coupons/updateCoupon',
  async (params: UpdateCouponParams, { rejectWithValue }) => {
    try {
      const response = await couponAPI.updateCoupon(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data: payload } = error.response;

        switch (payload.errorCode) {
          case ERROR_AUTHORIZATION_FAILURE:
          case ERROR_UNEXPECTED_ERROR: {
            return rejectWithValue(payload);
          }
          default: {
            break;
          }
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

type CreatePromotionCodeResponse = {};

export const createPromotionCode = createAsyncThunk<
  CreatePromotionCodeResponse,
  CreatePromotionCodeParams,
  GeneralAsyncThunkConfig
>(
  'coupons/createPromotionCode',
  async (params: CreatePromotionCodeParams, { rejectWithValue }) => {
    try {
      const response = await couponAPI.createPromotionCode(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data: payload } = error.response;

        switch (payload.errorCode) {
          case ERROR_AUTHORIZATION_FAILURE:
          case ERROR_UNEXPECTED_ERROR:
          case ERROR_PROMOTION_CODE_NOTFOUND:
          case ERROR_PROMOTION_CODE_EXISTS: {
            return rejectWithValue(payload);
          }
          default: {
            break;
          }
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);
export type UpdatePromotionCodeResponse = {};
export type UpdatePromotionCodeParams = {
  promotionCodeId: string;
  active: boolean;
};

export const updatePromotionCode = createAsyncThunk<
  UpdatePromotionCodeResponse,
  UpdatePromotionCodeParams,
  GeneralAsyncThunkConfig
>(
  'coupons/updatePromotionCode',
  async (params: UpdatePromotionCodeParams, { rejectWithValue }) => {
    try {
      const response = await couponAPI.updatePromotionCode(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data: payload } = error.response;

        switch (payload.errorCode) {
          case ERROR_AUTHORIZATION_FAILURE:
          case ERROR_UNEXPECTED_ERROR: {
            return rejectWithValue(payload);
          }
          default: {
            break;
          }
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

const settingSlice = createSlice({
  name: '@@merchant-dashboard/coupon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCoupon.fulfilled, () => {});
    builder.addCase(createPromotionCode.fulfilled, () => {});
  },
});

export default settingSlice.reducer;
