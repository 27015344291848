import { Trans } from 'react-i18next';
import IconInfoGray from '../../assets/icon-info-gray.svg';
import Popup from '../Popup/Popup';
import styles from './PasswordDescription.module.scss';

const PasswordDescription = ({
  desc,
  htmlFor,
}: {
  desc: string;
  htmlFor?: string;
}) => (
  <div className={styles['desc-container']}>
    <label className={styles.desc} htmlFor={htmlFor}>
      {desc}
    </label>
    <Popup
      trigger={
        <img
          src={IconInfoGray}
          width={20}
          height={20}
          loading="lazy"
          alt="Password must"
        />
      }
      content={
        <div className={styles['popup-content']}>
          <Trans
            i18nKey="reset-password.rules-desc"
            components={{
              title: <em />,
              list: <ul />,
              item: <li />,
            }}
          />
        </div>
      }
    />
  </div>
);

export default PasswordDescription;
