import React from 'react';
import styles from './RadioButton.module.scss';

const RadioButton = ({
  id = '',
  checked = false,
  onClick = () => {},
  title,
}: {
  id?: string;
  title: string;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div id={id} className={styles['radio-btn-container']} onClick={onClick}>
      <input type="radio" checked={checked} />
      <i>{title}</i>
    </div>
  );
};

export default RadioButton;
