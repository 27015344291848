import { DEFAULT_PAGE_SIZE } from '../constants';
import { Coupon, PromotionCode } from '../types/coupon';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

export interface GetCouponParams extends TestParams, PageParams {}

export interface PromotionCodeParams extends TestParams, PageParams {
  couponId: string;
}

export const couponAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    coupons: builder.query<ListResponse<Coupon>, GetCouponParams>({
      query: (params: GetCouponParams) => {
        return {
          url: `/me/coupons`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    coupon: builder.query<Coupon, string>({
      query: (couponId) => ({
        url: `/me/coupons/${couponId}`,
      }),
    }),
    promotionCodes: builder.query<
      ListResponse<PromotionCode>,
      PromotionCodeParams
    >({
      query: (params: PromotionCodeParams) => {
        const { couponId, ...restParams } = params;

        return {
          url: `/me/promotion-codes`,
          params: {
            ...restParams,
            // Keep using couponId for better readability
            coupon: couponId,
          },
        };
      },
    }),
    promotionCode: builder.query<PromotionCode, string>({
      query: (promotionCodeId) => {
        return {
          url: `/me/promotion-codes/${promotionCodeId}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCouponsQuery,
  useCouponQuery,
  usePromotionCodesQuery,
  usePromotionCodeQuery,
} = couponAPI;
