import * as Sentry from '@sentry/react';

/*
 * A helper method to make sure all errors inside event handlers
 * can be captured and sent to Sentry
 */
const createEventHandler = <T extends Array<unknown>, U>(
  fn: (...args: T) => Promise<U> | U
) => {
  return async (...args: T) => {
    try {
      return await fn(...args);
    } catch (e) {
      Sentry.captureException(e);

      return e;
    }
  };
};

export default createEventHandler;
