import Mixpanel from '@smartpay/mixpanel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Form/Button';
import { CANCEL_ORDER_MODAL_SCREEN } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { cancelOrder } from '../../redux/order';
import { Order } from '../../types/order';
import { emptyFn } from '../../utils/helper';
import styles from './Modal.module.scss';

const screen = CANCEL_ORDER_MODAL_SCREEN;

const CancelOrderModal = ({
  order,
  onCancelSuccessful = emptyFn,
  onDismiss,
}: {
  order: Order;
  onCancelSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div className={styles['modal-background']}>
      <div className={styles.modal}>
        <div>
          <h3 id="cancel_order_modal_title">{t('cancel-order-modal.title')}</h3>
          <p>{t('cancel-order-modal.desc')}</p>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="cancel_order_cancel"
            label={t('cancel-order-modal.cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'No',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_cancel_order_confirm"
            label={t('cancel-order-modal.confirm-btn')}
            size="small"
            onClick={async () => {
              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Yes',
              });

              const resultAction = await dispatch(cancelOrder(order.id));

              if (cancelOrder.fulfilled.match(resultAction)) {
                onCancelSuccessful();
              } else {
                const { payload } = resultAction;

                switch (payload?.errorCode) {
                  default: {
                    setErrorMessage(t('error.unspecific'));
                    break;
                  }
                }

                setIsLoading(false);
                return;
              }

              onDismiss();
            }}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelOrderModal;
