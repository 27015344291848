import cx from 'classnames';
import React, { ReactElement } from 'react';
import IconLoadingOutlineSmall from '../../assets/icon-button-loading-outline-small.svg';
import IconLoadingOutline from '../../assets/icon-button-loading-outline.svg';
import IconLoadingSmall from '../../assets/icon-button-loading-small.svg';
import IconLoading from '../../assets/icon-button-loading.svg';
import styles from './Button.module.scss';

export type ButtonColor = '';

export interface ButtonProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'large' | 'small';
  iconType?: 'info' | 'warning' | 'none';
  label: string;
  processing?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  inputRef?: React.Ref<HTMLButtonElement>;
}

const getLoadingIcon = ({
  size,
  variant,
}: {
  size: 'large' | 'small';
  variant: 'primary' | 'secondary' | 'outline';
}) => {
  if (variant === 'outline') {
    return size === 'small' ? IconLoadingOutlineSmall : IconLoadingOutline;
  }

  return size === 'small' ? IconLoadingSmall : IconLoading;
};

const Button = ({
  id = '',
  type = 'button',
  variant = 'primary',
  size = 'large',
  iconType = 'none',
  label,
  processing = false,
  disabled = false,
  className,
  style,
  onClick = () => {},
  inputRef,
  ...props
}: ButtonProps): ReactElement => (
  <button
    id={id}
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(
      styles.btn,
      styles[variant],
      styles[size],
      styles[iconType],
      processing && styles.processing,
      className
    )}
    onClick={onClick}
    disabled={disabled}
    ref={inputRef}
    {...props}
    style={style}
  >
    {processing ? (
      <img
        src={getLoadingIcon({ size, variant })}
        alt={label}
        className={cx(
          styles.processing,
          size === 'large' ? styles.large : styles.small
        )}
      />
    ) : (
      label
    )}
  </button>
);

export default Button;
