import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './LabeledComponent.module.scss';

const LabeledComponent = ({
  label = '',
  value = '',
  children = null,
  className = '',
  labelSpace = 'normal',
}: {
  label?: string;
  value?: string;
  children?: ReactNode;
  className?: string;
  labelSpace?: 'normal' | 'small';
}) => {
  return (
    <div className={cx(styles['labeled-container'], className)}>
      <label className={labelSpace === 'small' ? styles['space-small'] : ''}>
        {label}
      </label>
      {value && <p>{value}</p>}
      {children && children}
    </div>
  );
};

export default LabeledComponent;
