import LoadingIcon from '../assets/loading.svg';
import styles from './Loading.module.scss';

const Loading = ({
  width = 100,
  top = 0,
}: {
  width?: number;
  top?: number;
}) => (
  <div className={styles['loading-wrapper']}>
    <img
      src={LoadingIcon}
      alt="Loading"
      width={width}
      height={width}
      style={{ marginTop: top }}
    />
  </div>
);

export default Loading;
