import { navigate, RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logotype.svg';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import PasswordDescription from '../../components/PasswordDescription/PasswordDescription';
import {
  FORM_STATE_PENDING,
  FORM_STATE_PROCESSING,
  FORM_STATE_SUCCESS,
} from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { resetPassword } from '../../redux/auth';
import {
  ERROR_PASSWORD_NOT_MATCH,
  ERROR_PASSWORD_NOT_SECURE,
  ERROR_REQUEST_INVALID,
  ERROR_RESOURCE_NOT_FOUND,
  ERROR_UNEXPECTED_ERROR,
} from '../../redux/error-codes';
import { MsgMapping } from '../../types';
import createEventHandler from '../../utils/create-event-handler';
import styles from './ResetPasswordStep2Screen.module.scss';

const ERROR_MSG_MAPPING: MsgMapping = {
  [ERROR_PASSWORD_NOT_MATCH]: 'Password not match',
  [ERROR_PASSWORD_NOT_SECURE]: 'Password is not secure',
  [ERROR_RESOURCE_NOT_FOUND]: 'Reset link is not valid',
};

interface ResetPasswordStep2Props extends RouteComponentProps {
  token?: string;
}

const ResetPasswordStep2Screen: FC<ResetPasswordStep2Props> = ({ token }) => {
  useTrackPageView({ screen: 'New Password' });
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formState, setFormState] = useState(FORM_STATE_PENDING);
  const [passwordErrorCode, setPasswordErrorCode] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const onSubmit = createEventHandler(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formState === FORM_STATE_PROCESSING) {
        return;
      }

      if (!token) {
        setErrorCode(ERROR_UNEXPECTED_ERROR);

        return;
      }

      if (password && password !== confirmPassword) {
        setErrorCode(ERROR_PASSWORD_NOT_MATCH);

        return;
      }

      setFormState(FORM_STATE_PROCESSING);

      const resultAction = await dispatch(
        resetPassword({ token, newPassword: password })
      );

      if (resetPassword.fulfilled.match(resultAction)) {
        setFormState(FORM_STATE_SUCCESS);
      } else {
        const responseErrorCode = resultAction?.payload?.errorCode;
        const responseErrorDetails = resultAction?.payload?.details;

        setFormState(FORM_STATE_PENDING);

        if (responseErrorCode === ERROR_REQUEST_INVALID) {
          if (
            responseErrorDetails &&
            responseErrorDetails.find((detail) => detail.path === 'newPassword')
          ) {
            setPasswordErrorCode(ERROR_PASSWORD_NOT_SECURE);

            return;
          }
        }

        if (responseErrorCode === ERROR_RESOURCE_NOT_FOUND) {
          setErrorCode(ERROR_RESOURCE_NOT_FOUND);

          return;
        }

        setErrorCode(ERROR_UNEXPECTED_ERROR);
      }
    }
  );

  return (
    <div className={styles.container}>
      <h1>
        <img src={logo} height={55} alt="Smartpay" />
      </h1>
      {formState === FORM_STATE_SUCCESS ? (
        <div className={styles.content}>
          <h2>パスワードが正常に変更されました</h2>
          <Button
            type="button"
            label="ダッシュボードに進む"
            onClick={() => navigate('/')}
          />
        </div>
      ) : (
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.content}>
            <h2>{t('reset-password-step-2.title')}</h2>
            <p>{t('reset-password-step-2.desc')}</p>
          </div>
          <PasswordDescription
            desc={t('reset-password-step-2.create-password.desc')}
            htmlFor="ip_password"
          />
          <fieldset>
            <TextInput
              name="password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
              aria-label={t('reset-password-step-2.create-password.desc')}
              placeholder={t(
                'reset-password-step-2.create-password.placeholder'
              )}
              autoComplete="no"
              errorMessage={
                passwordErrorCode &&
                t(ERROR_MSG_MAPPING[passwordErrorCode] || passwordErrorCode)
              }
            />
          </fieldset>
          <fieldset>
            <TextInput
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(event) =>
                setConfirmPassword(event.currentTarget.value)
              }
              label={t('reset-password-step-2.confirm-password')}
              aria-label={t('reset-password-step-2.confirm-password')}
              placeholder={t(
                'reset-password-step-2.create-password.placeholder'
              )}
              autoComplete="no"
              errorMessage={
                errorCode && t(ERROR_MSG_MAPPING[errorCode] || errorCode)
              }
            />
          </fieldset>
          <Button
            type="submit"
            label={t('continue-btn')}
            processing={formState === FORM_STATE_PROCESSING}
            disabled={!password || !confirmPassword}
          />
        </form>
      )}
      <Footer />
    </div>
  );
};

export default ResetPasswordStep2Screen;
