import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// eslint-disable-next-line max-len
import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { isValid } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import LabeledComponent from '../../components/Form/LabeledComponent';
import RadioButton from '../../components/Form/RadioButton';
import TextInput from '../../components/Form/TextInput';
import { COUPON_CREATION_SCREEN } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { createCoupon } from '../../redux/coupon';
import { DiscountType } from '../../types/coupon';
import { useDataMode } from '../../utils/helper';
import styles from './CreateCouponModal.module.scss';

const emptyFn = () => {};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4456dd',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #dfe1e5', // default
            },
            '&:hover fieldset': {
              border: '1px solid #dfe1e5', // hover
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4456dd', // focus
            },
          },
        },
      },
    },
  },
});

const CreateCouponModal = ({
  onCreateCouponSuccessful = emptyFn,
  onDismiss,
}: {
  onCreateCouponSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t, i18n } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const { test: isTestMode } = useDataMode();

  const [couponName, setCouponName] = useState('');
  const [couponNameError, setCouponNameError] = useState('');
  const [hasMaxRedemptions, setHasMaxRedemptions] = useState(false);
  const [hasExpiryDate, setHasExpiryDate] = useState(false);
  const [discountType, setDiscountType] = useState<DiscountType>('percentage');
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountAmountError, setDiscountAmountError] = useState<string>('');
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [discountPercentageError, setDiscountPercentageError] =
    useState<string>('');
  const [discountMax, setDiscountMax] = useState<number | null>(null);
  const [discountMaxError, setDiscountMaxError] = useState<string>('');

  const [maxRedemptionCount, setMaxRedemptionCount] = useState(0);
  const [maxRedemptionCountError, setMaxRedemptionCountError] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [expiryDateError, setExpiryDateError] =
    useState<DateTimeValidationError | null>(null);

  return (
    <div className={styles['modal-background']}>
      <div className={cx(styles.modal, isTestMode ? styles.test : null)}>
        <div>
          <h3 id="create-coupon-modal-title">
            {t('create-coupon-modal.title')}
          </h3>
          <p>{t('create-coupon-modal.desc')}</p>
        </div>
        <div className={styles.body}>
          <fieldset>
            <TextInput
              autoFocus
              name="coupon-name"
              label={t('create-coupon-modal.coupon-name.label')}
              placeholder={t('create-coupon-modal.coupon-name.placeholder')}
              value={couponName}
              onChange={(event) => {
                setCouponName(event.currentTarget.value);
                if (couponNameError !== '') {
                  setCouponNameError('');
                }
              }}
              onBlur={(event) => {
                const { value } = event.currentTarget;

                if (value === '') {
                  setCouponNameError(
                    t('create-coupon-modal.coupon-name.is-required')
                  );
                }
              }}
              errorMessage={couponNameError}
            />
          </fieldset>
          <fieldset>
            <LabeledComponent
              label={t('create-coupon-modal.discount-type.title')}
              labelSpace="small"
            >
              <RadioButton
                id="discount-type-percent-discount"
                title={t('create-coupon-modal.discount-type.percent-discount')}
                checked={discountType === 'percentage'}
                onClick={() => {
                  setDiscountType('percentage');
                }}
              />
              <RadioButton
                id="discount-type-amount-discount"
                title={t('create-coupon-modal.discount-type.amount-discount')}
                checked={discountType === 'amount'}
                onClick={() => {
                  setDiscountType('amount');
                }}
              />
            </LabeledComponent>
          </fieldset>

          <fieldset>
            {discountType === 'percentage' && (
              <>
                <TextInput
                  name="discount-rate"
                  label={t('create-coupon-modal.percent-discount.label')}
                  placeholder=""
                  type="number"
                  rightText="%"
                  value={discountPercentage.toString()}
                  onChange={(event) => {
                    const value = Number(event.currentTarget.value);

                    setDiscountPercentage(Math.max(Math.min(100, value), 0));
                  }}
                  onBlur={(event) => {
                    const value = Number(event.currentTarget.value);

                    if (value <= 0 || value > 100) {
                      setDiscountPercentageError(
                        t('create-coupon-modal.percent-discount.out-of-range')
                      );
                    } else {
                      setDiscountPercentage(value);
                      setDiscountPercentageError('');
                    }
                  }}
                  errorMessage={discountPercentageError}
                />
                <TextInput
                  name="discount-rate"
                  label={t('create-coupon-modal.max-discount.label')}
                  placeholder=""
                  type="number"
                  rightText="￥"
                  value={discountMax?.toString() || ''}
                  onChange={(event) => {
                    const value = Number(event.currentTarget.value) || null;

                    setDiscountMax(value);
                  }}
                  onBlur={(event) => {
                    const value = Number(event.currentTarget.value) || null;

                    if (Number.isNaN(value)) {
                      setDiscountMaxError(
                        t('create-coupon-modal.max-discount.invalid')
                      );
                    } else {
                      setDiscountMax(value);
                      setDiscountMaxError('');
                    }
                  }}
                  errorMessage={discountMaxError}
                />
              </>
            )}
            {discountType === 'amount' && (
              <TextInput
                name="discount-amount"
                label={t('create-coupon-modal.amount-discount.label')}
                placeholder=""
                type="number"
                rightText="￥"
                value={discountAmount.toString()}
                onChange={(event) => {
                  setDiscountAmount(Number(event.currentTarget.value));
                  if (discountAmountError !== '') {
                    setDiscountAmountError('');
                  }
                }}
                onBlur={(event) => {
                  const value = Number(event.currentTarget.value);

                  if (value < 1) {
                    setDiscountAmountError(
                      t('create-coupon-modal.amount-discount.at-least-1-yen')
                    );
                  } else {
                    setDiscountAmountError('');
                  }
                }}
                errorMessage={discountAmountError}
              />
            )}
          </fieldset>
          <fieldset>
            <Checkbox
              label={t('create-coupon-modal.max-redemption.title')}
              name="max-redemption-count-checkbox"
              checked={hasMaxRedemptions}
              onChange={(event) =>
                setHasMaxRedemptions(event.currentTarget.checked)
              }
            />
            {hasMaxRedemptions && (
              <TextInput
                autoFocus
                name="max-redemption-count"
                type="number"
                rightText="回"
                value={maxRedemptionCount.toString()}
                onChange={(event) => {
                  const value = Number(event.currentTarget.value);

                  setMaxRedemptionCount(value);

                  if (value > 0) {
                    setMaxRedemptionCountError('');
                  }
                }}
                onBlur={(event) => {
                  const value = Number(event.currentTarget.value);

                  if (value < 1) {
                    setMaxRedemptionCountError(
                      t('create-coupon-modal.max-redemption.at-least-1-time')
                    );
                  } else {
                    setMaxRedemptionCountError('');
                  }
                }}
                errorMessage={maxRedemptionCountError}
              />
            )}
          </fieldset>
          <fieldset>
            <Checkbox
              label={t('create-coupon-modal.expiry-date.title')}
              name="coupon-deadline"
              checked={hasExpiryDate}
              onChange={(event) =>
                setHasExpiryDate(event.currentTarget.checked)
              }
            />
            {hasExpiryDate && (
              <div>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={i18n.language === 'ja' ? ja : enUS}
                  >
                    <DateTimePicker
                      value={expiryDate}
                      onChange={(newValue) => setExpiryDate(newValue)}
                      ampm={false}
                      minDateTime={new Date()}
                      inputFormat="yyyy/MM/dd HH:mm"
                      renderInput={(params) => (
                        <TextField autoFocus {...params} />
                      )}
                      onError={(err) => setExpiryDateError(err)}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
            )}
          </fieldset>
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="btn_create_coupon_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen: COUPON_CREATION_SCREEN,
                action: 'Click',
                itemName: 'Create Coupon Cancel',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_create_coupon_confirm"
            label={t('create-coupon-modal.create-a-coupon-btn')}
            size="small"
            disabled={
              couponName.length < 1 ||
              (discountType === 'amount' && discountAmount < 1) ||
              (discountType === 'percentage' && discountPercentage === 0) ||
              (discountType === 'percentage' && discountPercentage > 100) ||
              (hasMaxRedemptions && maxRedemptionCount < 1) ||
              (hasExpiryDate && !isValid(expiryDate)) ||
              (hasExpiryDate && expiryDateError !== null)
            }
            onClick={async () => {
              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen: COUPON_CREATION_SCREEN,
                action: 'Click',
                itemName: 'Create Coupon Confirm',
              });

              const resultAction = await dispatch(
                createCoupon({
                  name: couponName,
                  discountType,
                  ...(discountType === 'amount' && {
                    discountAmount,
                    currency: 'jpy',
                  }),
                  ...(discountType === 'percentage' && { discountPercentage }),
                  ...(discountType === 'percentage' &&
                    discountMax && { discountMax }),
                  ...(hasExpiryDate && { expiresAt: Number(expiryDate) }),
                  ...(hasMaxRedemptions && { maxRedemptionCount }),
                  test: isTestMode,
                  active: true,
                })
              );

              if (createCoupon.fulfilled.match(resultAction)) {
                onCreateCouponSuccessful();
              } else {
                const { payload } = resultAction;

                setCouponNameError(payload?.message || t('error.unspecific'));
                setIsLoading(false);
                return;
              }

              onDismiss();
            }}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCouponModal;
