import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { SMARTPAY_DOCUMENT_URL } from '../../constants';
import APIKeyInput from './APIKeyInput';
import styles from './CredentialsScreen.module.scss';
import SettingNavBar from './SettingNavBar';
import { useApiKeysQuery } from '../../services/setting';
import { KeySet } from '../../types/setting';
import useAppSelector from '../../hooks/use-app-selector';

const parseApiKeySets = (apiKeySets: KeySet[] | undefined) => {
  let livePublicKey;
  let livePrivateKey;
  let testPublicKey;
  let testPrivateKey;

  (apiKeySets || []).forEach((keySet) => {
    switch (keySet.kind) {
      case 'live':
        livePublicKey = keySet.publicKey;
        livePrivateKey = keySet.privateKey;
        break;
      case 'test':
        testPublicKey = keySet.publicKey;
        testPrivateKey = keySet.privateKey;
        break;
      default:
    }
  });

  return {
    livePublicKey,
    livePrivateKey,
    testPublicKey,
    testPrivateKey,
  };
};

const CredentialsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');
  const impersonate = useAppSelector((state) => state.auth.impersonate);
  const { data: apiKeySets } = useApiKeysQuery(undefined, {
    skip: impersonate,
  });

  const { livePublicKey, livePrivateKey, testPublicKey, testPrivateKey } =
    parseApiKeySets(apiKeySets);

  return (
    <Layout title={t('settings.title')} className={styles.layout}>
      <SettingNavBar />
      <div className={styles.container}>
        <section>
          <h2>{t('settings.credentials.test-api-key')}</h2>
          <APIKeyInput
            label={t('settings.credentials.public-key')}
            name="test_public_key"
            itemName="Test Public Key"
            value={testPublicKey}
          />
          <APIKeyInput
            label={t('settings.credentials.secret-key')}
            name="test_secret_key"
            itemName="Test Secret Key"
            value={testPrivateKey}
            masked
            maskable
          />
        </section>
        {livePublicKey && livePrivateKey && (
          <section>
            <h2>{t('settings.credentials.live-api-key')}</h2>
            <APIKeyInput
              label={t('settings.credentials.public-key')}
              name="live_public_key"
              itemName="Live Public Key"
              value={livePublicKey}
            />
            <APIKeyInput
              label={t('settings.credentials.secret-key')}
              name="live_secret_key"
              itemName="Live Secret Key"
              value={livePrivateKey}
              masked
              maskable
            />
          </section>
        )}
      </div>
      <p className={styles.contact}>
        <Trans
          i18nKey="settings.credentials.desc"
          components={{
            documentLink: (
              <a
                href={SMARTPAY_DOCUMENT_URL}
                onClick={() => {
                  Mixpanel.trackAction({
                    action: 'Click',
                    itemName: 'Documentation',
                  });
                }}
              >
                Learn more
              </a>
            ),
          }}
        />
      </p>
    </Layout>
  );
};

export default CredentialsScreen;
