import cx from 'classnames';
import { SyntheticEvent, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import PaymentLinkAPI from '../../api/payment-link';
import IconCheck from '../../assets/icon-checked-20.svg';
import IconCopyGray from '../../assets/icon-content-copy-gray.svg';
import IconCopy from '../../assets/icon-content-copy.svg';
import Button from '../../components/Form/Button';
import Popup from '../../components/Popup/Popup';
import type { PaymentLink } from '../../types/payment-link';
import { currencyFormatter, getLineItemsAmount } from '../../utils';
import createEventHandler from '../../utils/create-event-handler';
import styles from './List.module.scss';
import Time from '../../components/Time/Time';

const Row = ({
  link,
  refetchList,
}: {
  link: PaymentLink;
  refetchList: Function;
}) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const onDeactivate = createEventHandler(
    async (e: SyntheticEvent<HTMLButtonElement>) => {
      setIsProcessing(true);

      const linkId = e.currentTarget.getAttribute('data-link-id');

      try {
        await PaymentLinkAPI.updateLinkById({
          id: linkId as string,
          changes: { active: false },
        });

        setIsProcessing(false);

        refetchList();
      } catch (err) {
        // TODO: show error message somewhere
        setIsProcessing(false);
      }
    }
  );

  const onActivate = createEventHandler(
    async (e: SyntheticEvent<HTMLButtonElement>) => {
      setIsProcessing(true);

      const linkId = e.currentTarget.getAttribute('data-link-id');

      try {
        await PaymentLinkAPI.updateLinkById({
          id: linkId as string,
          changes: { active: true },
        });

        setIsProcessing(false);

        refetchList();
      } catch (err) {
        // TODO: show error message somewhere
        setIsProcessing(false);
      }
    }
  );

  return (
    <tr className={styles.row} key={link.id}>
      <td className="align-center">
        <Popup
          direction="up"
          content={
            <div className={styles['popup-content']}>
              {isCopied ? (
                <div className={styles['copied-content']}>
                  <img
                    className={styles['img-copy']}
                    src={IconCheck}
                    width={20}
                    height={20}
                    alt=""
                    loading="eager"
                  />
                  <p>Copied</p>
                </div>
              ) : (
                <span>{link.url}</span>
              )}
            </div>
          }
          trigger={
            <div onMouseLeave={() => setIsCopied(false)}>
              <CopyToClipboard
                text={link.url}
                onCopy={() => {
                  setIsCopied(true);
                }}
              >
                <img
                  src={link.active ? IconCopy : IconCopyGray}
                  width={16}
                  height={16}
                  alt="Copy"
                  loading="eager"
                  className={styles['btn-copy']}
                />
              </CopyToClipboard>
            </div>
          }
        />
      </td>
      <td>
        <div className={styles.items}>
          {link.lineItems
            .filter((item) => !!item.price.product.name.trim())
            .map((item) => {
              return (
                <div
                  key={item.id}
                  className={cx(styles.item, !link.active && styles.inactive)}
                >
                  {item.price.product.name.trim()}
                </div>
              );
            })}
        </div>
      </td>
      <td
        className={cx(
          'align-right mono',
          styles.amount,
          !link.active && styles.inactive
        )}
      >
        ¥{currencyFormatter(getLineItemsAmount(link.lineItems))}
      </td>
      <td className={cx('mono', !link.active && styles.inactive)}>
        <Time
          dateTime={link.createdAt}
          formatStr={t('format.yyyyMMMddHHmmss', 'yyyy/MM/dd HH:mm:ss')}
        />
      </td>
      <td>
        {link.active ? (
          <Button
            id="btn_deactivate"
            label={t('payment-link.action.deactivate')}
            size="small"
            variant="outline"
            className={styles['btn-deactivate']}
            data-link-id={link.id}
            onClick={onDeactivate}
            processing={isProcessing}
          />
        ) : (
          <Button
            id="btn_activate"
            label={t('payment-link.action.activate')}
            size="small"
            className={styles['btn-activate']}
            data-link-id={link.id}
            onClick={onActivate}
            processing={isProcessing}
          />
        )}
      </td>
    </tr>
  );
};

export default Row;
