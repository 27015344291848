import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import sumBy from 'lodash.sumby';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_DETAIL_SCREEN } from '../../constants';
import {
  Order,
  TaxLineItem,
  DiscountLineItem,
  ProductLineItem,
} from '../../types/order';
import { formatCurrency } from '../../utils/helper';
import { isDiscount, isProduct, isTax } from './utils';
import srcArrow from '../../assets/icon-arrow-right.svg';

import styles from './ReceiptView.module.scss';

const screen = ORDER_DETAIL_SCREEN;

const ReceiptView = ({ order }: { order: Order }) => {
  const { t } = useTranslation('translation');
  const {
    lineItems,
    discounts,
    amount: orderAmount = 0,
    discountAmount = 0,
    shippingInfo,
  } = order;
  const [isCollapse, setIsCollapse] = useState(true);

  const productLineItems = useMemo(() => {
    return lineItems?.filter(isProduct) || ([] as Array<ProductLineItem>);
  }, [lineItems]);

  const lineItemsTotalDiscountAmount = sumBy(
    (order.lineItems?.filter(isDiscount) || []) as Array<DiscountLineItem>,
    'amount'
  );

  const lineItemsTotalTaxAmount = sumBy(
    (order.lineItems?.filter(isTax) || []) as Array<TaxLineItem>,
    'amount'
  );

  const cunsumerPayAmount = orderAmount - discountAmount;

  return (
    <div className={styles['receipt-container']}>
      <h2
        onClick={() => {
          Mixpanel.trackAction({
            action: 'Click',
            itemName: 'See all products',
            screen,
          });

          setIsCollapse(!isCollapse);
        }}
        className={styles.title}
      >
        <span>
          {t('order.details')}
          {productLineItems.length > 0 && (
            <i>
              {t('order.number-of-items', {
                number: productLineItems.length,
              })}
            </i>
          )}
        </span>
        <img
          src={srcArrow}
          width="24"
          height="24"
          alt="expand"
          className={cx(
            styles.btn,
            isCollapse ? styles.collapsed : styles.expanded
          )}
        />
      </h2>
      {!isCollapse && (
        <div className={cx(styles.receipt)}>
          {productLineItems.map((item: ProductLineItem, i) => (
            <div className={styles.item} key={i}>
              <span>
                {item.price.product.name}
                {item.description && <span>{item.description}</span>}
              </span>
              <span>
                <b>{formatCurrency(item.price.amount, item.price.currency)}</b>
                <span>×{item.quantity}</span>
              </span>
            </div>
          ))}
        </div>
      )}
      <hr />
      <div className={cx(styles.receipt)}>
        {shippingInfo &&
          shippingInfo?.feeAmount > 0 &&
          shippingInfo?.feeCurrency && (
            <div className={styles.item}>
              <span>{t('order.receipt.shipping-fee')}</span>
              <span>
                {formatCurrency(
                  shippingInfo?.feeAmount,
                  shippingInfo?.feeCurrency
                )}
              </span>
            </div>
          )}

        {!!lineItemsTotalDiscountAmount && (
          <div className={styles.item}>
            <span>{t('order.receipt.discount')}</span>
            <span>-{formatCurrency(lineItemsTotalDiscountAmount, 'JPY')}</span>
          </div>
        )}

        {!!lineItemsTotalTaxAmount && (
          <div className={styles.item}>
            <span>{t('order.receipt.tax')}</span>
            <span>{formatCurrency(lineItemsTotalTaxAmount, 'JPY')}</span>
          </div>
        )}
        <div className={cx(styles.item, styles.total)}>
          <span>
            <b>
              {t('order.receipt.total')}
              <span className={styles.note}>
                （{t('order.receipt.tax-included')}）
              </span>
            </b>
          </span>

          <span>{formatCurrency(order.amount, order.currency)}</span>
        </div>
      </div>
      {!!discounts?.length && (
        <>
          <hr />
          <div className={cx(styles.receipt)}>
            {discounts.map((discount) => (
              <div className={styles.item} key={discount.id}>
                <div>
                  <span className={styles['coupon-type']}>
                    {discount.sponsored
                      ? 'SMARTPAY SPONSORED COUPON'
                      : 'SMARTPAY MERCHANT COUPON'}
                  </span>
                  <span>{discount.promotionCode.code}</span>
                  <span className={styles.note}>
                    {t('order.receipt.promotioncode')}
                  </span>
                </div>
                <span>
                  {formatCurrency(discount.amount * -1, discount.currency)}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      <hr className={cx(styles.major)} />
      <div className={cx(styles.receipt)}>
        <div className={cx(styles.item, styles.total)}>
          <span>{t('order.payment')}</span>
          <span>{formatCurrency(cunsumerPayAmount, order.currency)}</span>
        </div>
      </div>
    </div>
  );
};

export default ReceiptView;
