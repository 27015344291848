import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams, StringParam } from 'use-query-params';
import IconPayoutActive from '../../assets/icon-sidebar-payout-active.svg';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import {
  PAYOUTS_SCREEN,
  SMARTPAY_DOCUMENT_URL,
  DEFAULT_PAGE_SIZE,
} from '../../constants';
import { usePayoutsQuery } from '../../services/payout';
import { useAccountStatus, useDataMode } from '../../utils/helper';
import styles from './PayoutListScreen.module.scss';
import PayoutRow from './PayoutRow';

const PayoutListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen: PAYOUTS_SCREEN });

  const { t } = useTranslation('translation');
  const { test: isTestAccount } = useAccountStatus();
  const { test: isTestDataMode } = useDataMode();
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    q: StringParam,
    statuses: StringParam,
  });
  const offset = query.offset || 0;
  const q = query.q || '';
  const statuses = query.statuses || '';
  const { data, isLoading } = usePayoutsQuery({
    offset,
    test: isTestDataMode,
    statuses,
    limit: DEFAULT_PAGE_SIZE,
    ...(q ? { q } : {}),
  });

  const payouts = data?.data;

  return (
    <Layout title={t('payout-listing.title')} isLoading={!payouts && isLoading}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.period}>
              {t('payout-listing.table-header.period')}
            </th>
            <th className={styles.status}>
              {t('payout-listing.table-header.status')}
            </th>
            <th className={styles.amount}>
              {t('payout-listing.table-header.amount')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!!payouts?.length &&
            payouts.map((payout, index) => (
              <PayoutRow payout={payout} key={index} />
            ))}
        </tbody>
      </table>
      {!payouts?.length ? (
        <EmptyStateView
          src={IconPayoutActive}
          title={t('payout-listing.empty-state.title')}
        >
          {isTestAccount && (
            <>
              <span>{t('payout-listing.empty-state.desc')}</span>
              <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
            </>
          )}
        </EmptyStateView>
      ) : (
        <PaginationView
          screen={PAYOUTS_SCREEN}
          hasPrevPage={offset > 0}
          hasNextPage={!!data?.nextOffset}
          onPrevClick={() => {
            setQuery(
              {
                offset: offset - DEFAULT_PAGE_SIZE,
              },
              'replaceIn'
            );
          }}
          onNextClick={() =>
            setQuery(
              {
                offset: data?.nextOffset,
              },
              'replaceIn'
            )
          }
        />
      )}
    </Layout>
  );
};

export default PayoutListScreen;
