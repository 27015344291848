import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './EmptyStateView.module.scss';

const EmptyStateView = ({
  src = '',
  title = '',
  size = 'large',
  children,
}: {
  src?: string;
  title?: string;
  size?: 'small' | 'large';
  children?: ReactNode;
}) => {
  return (
    <div
      className={cx(
        styles['empty-state-container'],
        size === 'small' ? styles.small : ''
      )}
    >
      <div>
        <div className={styles.headline}>
          {src && <img src={src} width={24} height={24} alt="" />}

          <span>{title}</span>
        </div>
        {children && (
          <div className={cx(styles.hint, src ? styles['left-padding'] : '')}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyStateView;
