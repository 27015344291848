import { format, formatRFC3339, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

const Time = ({
  dateTime,
  onEmpty,
  formatStr: customFormatStr,
  formatDateStr = '',
  formatTimeStr = '',
  className,
}: {
  dateTime?: string | number | null;
  onEmpty?: string;
  formatStr?: string;
  formatDateStr?: string;
  formatTimeStr?: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  if (!dateTime) {
    return <>{onEmpty}</> || null;
  }

  const defaultFormatStr = t('format.yyyyMMMdd', 'yyyy/MM/dd');
  const formatStr = customFormatStr || defaultFormatStr;
  const date =
    typeof dateTime === 'string' ? parseISO(dateTime) : new Date(dateTime);

  const humanFullDatetime = format(
    date,
    t('format.yyyyMMMddHHmmss', 'yyyy/MM/dd HH:mm:ss')
  );

  const humanDatetime = formatStr ? format(date, formatStr) : '';
  const humanDate = formatDateStr ? format(date, formatDateStr) : '';
  const humanTime = formatTimeStr ? format(date, formatTimeStr) : '';

  const children =
    humanDate && humanTime ? [humanDate, humanTime] : [humanDatetime];

  return (
    <time
      dateTime={formatRFC3339(date)}
      title={humanFullDatetime}
      className={className}
    >
      {children.map((str, i) => (
        <span key={i}>{str}</span>
      ))}
    </time>
  );
};

export default Time;
