import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import settingAPI from '../api/setting';

type BankAccount = {
  bankCode: string;
  branchCode: string;
  accountNumber: string;
  accountType: string;
  name: string;
  country: string;
  currency: string;
  status: 'active' | 'inactive';
};

type Fee = {
  limit: {
    lower: number;
    upper: number;
  };
  fixedAmount: number;
  rate: number;
};

type FeePlan = {
  activatedAt: string;
  active: boolean;
  fees: Fee[];
};

type PayoutSchedule = {
  schedule: string;
  period: {
    start: string;
    end: string;
    nextPaymentAt: string;
  };
};

const initialState: {
  bankAccount: BankAccount;
  feePlan: FeePlan;
  payoutSchedule: PayoutSchedule;
} = {
  bankAccount: {
    bankCode: '',
    branchCode: '',
    accountNumber: '',
    accountType: '',
    name: '',
    country: '',
    currency: '',
    status: 'inactive',
  },
  feePlan: {
    activatedAt: '',
    active: true,
    fees: [],
  },
  payoutSchedule: {
    schedule: '',
    period: {
      start: '',
      end: '',
      nextPaymentAt: '',
    },
  },
};

export const getBankAccountsInfo = createAsyncThunk(
  'setting/bank',
  async () => {
    const response = await settingAPI.getBankAccounts();

    return response.data;
  }
);

export const getFeePlans = createAsyncThunk('setting/feePlan', async () => {
  const response = await settingAPI.getFeePlans();

  return response.data;
});

export const getCurrentPayoutSchedule = createAsyncThunk(
  'setting/payoutSchedule',
  async () => {
    const response = await settingAPI.getCurrentPayoutSchedule();

    return response.data;
  }
);

const settingSlice = createSlice({
  name: '@@merchant-dashboard/setting-payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankAccountsInfo.fulfilled, (state, action) => {
      const bankAccounts = action.payload;

      state.bankAccount =
        bankAccounts.find((e: BankAccount) => e.status === 'active') ||
        initialState.bankAccount;
    });

    builder.addCase(getFeePlans.fulfilled, (state, action) => {
      const feePlans = action.payload;

      state.feePlan =
        feePlans.find((e: FeePlan) => e.active) || initialState.feePlan;
    });

    builder.addCase(getCurrentPayoutSchedule.fulfilled, (state, action) => {
      state.payoutSchedule = action.payload || initialState.payoutSchedule;
    });
  },
});

export default settingSlice.reducer;
