import { TestParams } from '../services/base';
import type {
  LineItemReq,
  PaymentLink,
  OnCompletion,
} from '../types/payment-link';
import axios from './api';

export type PaymentLinkParams = TestParams;

const PaymentLinkAPI = {
  createLink: ({
    payload,
  }: {
    payload: {
      items: LineItemReq[];
      test: boolean;
      onCompletion?: OnCompletion;
    };
  }) => axios.post(`/merchants/me/payment-links`, payload),
  updateLinkById: ({
    id,
    changes,
  }: {
    id: string;
    changes: Partial<PaymentLink>;
  }) => axios.patch(`/merchants/me/payment-links/${id}`, { ...changes }),
};

export default PaymentLinkAPI;
