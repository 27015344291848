import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const settingSlice = createSlice({
  name: '@@merchant-dashboard/setting-api-keys',
  initialState,
  reducers: {},
});

export default settingSlice.reducer;
