import React from 'react';
import styles from './TextArea.module.scss';

const emptyFn = () => {};

const TextArea = ({
  label = '',
  name = '',
  placeholder = '',
  value = '',
  onChange = emptyFn,
}: {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <div className={styles.container}>
      <label htmlFor={`ip_${name}`}>{label}</label>
      <div id={`ip_${name}`} className={styles['textarea-wrapper']}>
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextArea;
