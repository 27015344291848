import {
  MaskitoOptions,
  MaskitoPostprocessor,
  maskitoTransform,
} from '@maskito/core';

const digitRegexp = /[0-9０-９]/;

const pinCodeMask = [
  digitRegexp,
  digitRegexp,
  digitRegexp,
  '-',
  digitRegexp,
  digitRegexp,
  digitRegexp,
];

const postProcessFullWidthDigits: MaskitoPostprocessor = ({
  value,
  selection,
}) => {
  return {
    value: value.replace(/[０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    ),
    selection,
  };
};

export const pinCodeMaskOption: MaskitoOptions = {
  mask: pinCodeMask,
  postprocessors: [postProcessFullWidthDigits],
};

export const pinCodeTransformer = (input: string) => {
  return maskitoTransform(input, pinCodeMaskOption);
};
