import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import React from 'react';
import TextInput from '../../components/Form/TextInput';
import { PROMOTION_CODE_DETAIL_SCREEN } from '../../constants';
import { Order } from '../../types/order';
import { formatCurrency } from '../../utils/helper';
import styles from './PromotionCodeOrderRow.module.scss';
import Time from '../../components/Time/Time';

const screen = PROMOTION_CODE_DETAIL_SCREEN;

const PromotionCodeOrderRow = ({
  order,
  promotionCode,
}: {
  order: Order;
  promotionCode: string;
}) => {
  return (
    <tr
      className={styles['promotion-code-order-row']}
      onClick={() => {
        Mixpanel.trackAction({
          screen,
          action: 'Click',
          itemName: 'Order',
        });
        navigate(`/orders/${order.id}`);
      }}
    >
      <td className={styles['order-id']}>
        <TextInput
          name="order_id"
          type="text"
          variant="clean"
          value={order.id}
          rightIcon="copy"
          rightIconColor="gray"
          size={order.id.length}
          onRightIconClick={() => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: 'Copy Order Id',
            });
          }}
          readOnly
        />
      </td>
      <td>{promotionCode}</td>
      <td className={styles.amount}>
        {formatCurrency(order.amount, order.currency)}
      </td>
      <td>
        <Time dateTime={order.createdAt} onEmpty="ー" formatStr="y/MM/dd" />
      </td>
    </tr>
  );
};

export default PromotionCodeOrderRow;
