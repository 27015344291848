import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import TextInput from '../../components/Form/TextInput';
import { COUPON_DETAIL_SCREEN } from '../../constants';
import { PromotionCode } from '../../types/coupon';
import { couponDetails } from '../../utils/helper';
import styles from './PromotionCodeRow.module.scss';
import RedemptionView from './RedemptionView';
import Time from '../../components/Time/Time';

const screen = COUPON_DETAIL_SCREEN;

const PromotionCodeRow = ({
  couponId,
  promotionCode,
}: {
  couponId: string;
  promotionCode: PromotionCode;
}) => {
  const { t } = useTranslation('translation');

  const { isEnded, isFinished, isExpired } = couponDetails(promotionCode);

  return (
    <tr
      className={cx(styles['promotion-code-row'])}
      onClick={() => {
        Mixpanel.trackAction({
          screen,
          action: 'Click',
          itemName: 'Promotion Code Item',
        });

        navigate(`/coupons/${couponId}/${promotionCode.id}`);
      }}
    >
      <td
        className={cx(
          styles.code,
          !promotionCode.active ? styles.inactive : ''
        )}
      >
        {promotionCode.code}
        {!promotionCode.active && <Capsule title="無効" size="small" />}
      </td>
      <td className={styles['promotion-code-id']}>
        <TextInput
          name="promotion_code_id"
          type="text"
          variant="clean"
          value={promotionCode.id}
          rightIcon="copy"
          rightIconColor="gray"
          size={promotionCode.id.length}
          onRightIconClick={() => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: 'Copy Promotion Code Id',
            });
          }}
          readOnly
        />
      </td>
      <td className={cx(styles.redemption, isFinished ? styles.finished : '')}>
        {isEnded && <Capsule title={t('end')} size="small" color="cerise" />}
        <RedemptionView
          redemptionCount={promotionCode.redemptionCount}
          maxRedemptionCount={promotionCode.maxRedemptionCount}
        />
      </td>
      <td
        className={cx(styles['expiry-date'], isExpired ? styles.expired : '')}
      >
        <Time
          dateTime={promotionCode.expiresAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
        />
      </td>
      <td>
        <Time dateTime={promotionCode.createdAt} formatStr="y/MM/dd" />
      </td>
    </tr>
  );
};

export default PromotionCodeRow;
