import Mixpanel from '@smartpay/mixpanel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Form/Button';
import TextArea from '../../components/Form/TextArea';
import TextInput from '../../components/Form/TextInput';
import { MANUAL_CAPTURE_MODAL_SCREEN } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { createCapture } from '../../redux/payment';
import { Order } from '../../types/order';
import createEventHandler from '../../utils/create-event-handler';
import { emptyFn } from '../../utils/helper';
import styles from './Modal.module.scss';

const screen = MANUAL_CAPTURE_MODAL_SCREEN;

const ManualCaptureModal = ({
  order,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCaptureSuccessful = emptyFn,
  onDismiss,
}: {
  order: Order;
  onCaptureSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const [capturableAmount, setAmount] = useState(order.amount);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div className={styles['modal-background']}>
      <div className={styles.modal}>
        <div>
          <h3 id="manual_capture_modal_title">
            {t('manual-capture-modal.title')}
          </h3>
          <p>{t('manual-capture-modal.desc')}</p>
        </div>
        <div className={styles['modal-description']}>
          <TextInput
            name="capture-amount"
            type="number"
            rightText={order.currency}
            label={t('manual-capture-modal.capture-label')}
            value={capturableAmount.toString()}
            onChange={(event) => {
              setAmount(Number(event.currentTarget.value));
            }}
            errorMessage={errorMessage}
            autoFocus
          />
          <TextArea
            name="manual-capture-description"
            label={t('manual-capture-modal.desc-label')}
            placeholder={t('manual-capture-modal.desc-placeholder')}
            value={description}
            onChange={(event) => {
              setDescription(event.currentTarget.value);
            }}
          />
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="btn_manual_capture_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Capture Cancel',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_capture_confirm"
            label={t('manual-capture-modal.confirm-btn')}
            size="small"
            onClick={createEventHandler(async () => {
              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Capture Confirm',
              });

              const resultAction = await dispatch(
                createCapture({
                  order: order.id,
                  amount: capturableAmount,
                  currency: order.currency,
                  description,
                })
              );

              if (createCapture.fulfilled.match(resultAction)) {
                onCaptureSuccessful();
              } else {
                const { payload } = resultAction;

                switch (payload?.errorCode) {
                  default: {
                    setErrorMessage(t('error.unspecific'));
                    break;
                  }
                }

                setIsLoading(false);
                return;
              }

              onDismiss();
            })}
            disabled={capturableAmount <= 0}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ManualCaptureModal;
