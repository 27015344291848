import flatten from 'lodash.flatten';
import {
  DiscountLineItem,
  LineItem,
  Order,
  ProductLineItem,
  TaxLineItem,
} from '../../types/order';
import { Fee, FeePlan } from '../../types/setting';
import sumBy from 'lodash.sumby';

export type OrderEventType =
  | 'succeeded'
  | 'uncaptured'
  | 'capture'
  | 'refund'
  | 'canceled';

export interface OrderEvent {
  type: OrderEventType;
  amount: number;
  currency?: string;
  time: number;
  note?: string;
}

export const isProduct = (item: LineItem): item is ProductLineItem =>
  item.kind === 'product';
export const isDiscount = (item: LineItem): item is DiscountLineItem =>
  item.kind === 'discount';
export const isTax = (item: LineItem): item is TaxLineItem =>
  item.kind === 'tax';

export const getCancelledAmount = (order: Order): number => {
  if (order.canceledAt) {
    const paymentAmount = sumBy(order.payments || [], 'amount');

    return order.amount - paymentAmount;
  }

  return 0;
};

export const getUnsponsoredDiscountAmount = (order?: Order): number => {
  return sumBy(
    order?.discounts?.filter((discount) => !discount.sponsored) || [],
    'amount'
  );
};

export const getSponsoredDiscountAmount = (order?: Order): number => {
  return sumBy(
    order?.discounts?.filter((discount) => discount.sponsored) || [],
    'amount'
  );
};

export const getOrderEvents = (order?: Order): OrderEvent[] => {
  if (!order || !order?.payments) {
    return [];
  }

  let restDiscountAmount = order.discountAmount;

  const payments = order.payments
    .slice()
    .sort((a, b) => a.createdAt - b.createdAt);

  const events = flatten<OrderEvent>(
    payments.map((payment) => {
      const discountedAmount = payment.amount - restDiscountAmount;
      const amount = Math.max(discountedAmount, 0);

      restDiscountAmount = Math.max(-discountedAmount, 0);

      return [
        {
          type: order.captureMethod === 'manual' ? 'capture' : 'succeeded',
          amount,
          currency: payment.currency,
          time: payment.createdAt,
        },
        ...(payment.refunds || []).map((refund) => {
          return {
            type: 'refund' as OrderEventType,
            amount: -refund.amount,
            currency: refund.currency,
            time: refund.createdAt,
          };
        }),
      ];
    })
  );

  events.sort((a, b) => a.time - b.time);

  // Distinguish manual capture
  if (!events.length) {
    events.unshift({
      type: 'uncaptured',
      amount: order.amount - order.discountAmount,
      currency: order.currency,
      time: order.createdAt,
    });
  }

  return events;
};

// The order amount contains the discountAmount
export const getCunsumerPayBaseAmount = (order?: Order): number => {
  return order ? order.amount - order.discountAmount : 0;
};

export const getRefundedAmount = (order?: Order): number => {
  return sumBy(
    flatten(order?.payments?.map((payment) => payment.refunds || []) || []),
    'amount'
  );
};

export const getDiscountAmount = (order?: Order): number => {
  return order
    ? Math.max(
        sumBy(
          order.discounts?.filter((discount) => discount.sponsored) || [],
          'amount'
        ),
        0
      )
    : 0;
};

export const getCapturedAmount = (order?: Order): number => {
  return order
    ? Math.max(
        sumBy(order.payments || [], 'amount') - getDiscountAmount(order),
        0
      )
    : 0;
};

export const getCurrentFee = (feePlan?: FeePlan) => {
  return feePlan?.fees?.[0];
};

export const getFeeAmount = (cunsumerPayAmount: number, fee?: Fee) => {
  return fee ? fee.rate * cunsumerPayAmount + fee.fixedAmount : undefined;
};

export const getMerchantPayoutAmount = (
  merchantPayoutBaseAmount: number,
  realFeeAmount?: number,
  expectedFeeAmount?: number
) => {
  if (realFeeAmount !== undefined) {
    return merchantPayoutBaseAmount - realFeeAmount;
  }

  if (expectedFeeAmount !== undefined) {
    return merchantPayoutBaseAmount - expectedFeeAmount;
  }

  return undefined;
};

export default { getOrderEvents };
