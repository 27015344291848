import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const SettingsScreen: FC<RouteComponentProps> = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    navigate('/settings/accounts');
  }, [dispatch, search]);

  return null;
};

export default SettingsScreen;
