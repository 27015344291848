import { navigate } from '@reach/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import authAPI, {
  ResendOTPParams,
  NewPasswordParams,
  ResetPasswordParam,
  SignInParams,
  SignInWithOtpParams,
  ImpersonateParams,
} from '../api/auth';
import {
  ERROR_NOT_AUTHORIZED,
  ERROR_OTP_NOT_MATCH,
  ERROR_REQUEST_INVALID,
  ERROR_RESOURCE_NOT_FOUND,
  ERROR_UNEXPECTED_ERROR,
} from './error-codes';

export const SIGNIN_STATUS_PENDING = 'SIGNIN_STATUS_PENDING';
export const SIGNIN_STATUS_PROCESSING = 'SIGNIN_STATUS_PROCESSING';
export const SIGNIN_STATUS_SUCCESS = 'SIGNIN_STATUS_SUCCESS';
export const SIGNIN_STATUS_ERROR = 'SIGNIN_STATUS_ERROR';

type SignInAttemptResponse = {
  accessToken?: string;
  signInAttemptId?: string;
  username: string;
};
type SignInwithOtpResponse = {
  accessToken: string;
};
export type RefreshResponse = {
  accessToken: string;
};
type ImpersonateResponse = {
  accessToken: string;
};
type SignOutResponse = {};
type ResetPasswordResponse = {};
type NewPasswordResponse = {};

export type ErrorDetail = {
  path: string;
  message: string[];
};

export type GeneralAsyncThunkConfig = {
  rejectValue: {
    errorCode: string;
    details?: ErrorDetail[];
  };
};

const isInProtectedPage = (path: string) => {
  return !(
    path.indexOf('/login') === 0 ||
    path.indexOf('/2fa') === 0 ||
    path.indexOf('/reset-password') === 0
  );
};

export const signIn = createAsyncThunk<
  SignInAttemptResponse,
  SignInParams,
  GeneralAsyncThunkConfig
>('auth/signIn', async (params: SignInParams, { rejectWithValue }) => {
  try {
    const response = await authAPI.signIn(params);

    return {
      username: params.username,
      accessToken: response.data.accessToken,
      signInAttemptId: response.data.signinAttemptId,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return rejectWithValue({
          errorCode: ERROR_REQUEST_INVALID,
          details: error.response.data?.details,
        });
      }

      if (error.response.status === 401) {
        return rejectWithValue({ errorCode: ERROR_NOT_AUTHORIZED });
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

export const resendOtp = createAsyncThunk<
  null,
  ResendOTPParams,
  GeneralAsyncThunkConfig
>('auth/resend-otp', async (params: ResendOTPParams, { rejectWithValue }) => {
  try {
    await authAPI.resendOtp(params);

    return null;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return rejectWithValue({
          errorCode: ERROR_REQUEST_INVALID,
          details: error.response.data?.details,
        });
      }

      if (error.response.status === 401) {
        return rejectWithValue({ errorCode: ERROR_NOT_AUTHORIZED });
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

export const signInWithOtp = createAsyncThunk<
  SignInwithOtpResponse,
  SignInWithOtpParams,
  GeneralAsyncThunkConfig
>(
  'auth/signInWithOtp',
  async (params: SignInWithOtpParams, { rejectWithValue }) => {
    try {
      const response = await authAPI.signInWithOtp(params);

      return {
        accessToken: response.data.accessToken,
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          return rejectWithValue({
            errorCode: ERROR_REQUEST_INVALID,
            details: error.response.data?.details,
          });
        }

        if (error.response.status === 401) {
          return rejectWithValue({ errorCode: ERROR_OTP_NOT_MATCH });
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

export const refreshToken = createAsyncThunk<
  RefreshResponse,
  any, // eslint-disable-line @typescript-eslint/no-explicit-any
  GeneralAsyncThunkConfig
>('auth/refresh', async (_, { rejectWithValue }) => {
  try {
    const response = await authAPI.refresh();

    return {
      accessToken: response.data.accessToken,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 401) {
        return rejectWithValue({ errorCode: ERROR_NOT_AUTHORIZED });
      }

      return rejectWithValue(error.response.data);
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

export const impersonate = createAsyncThunk<
  ImpersonateResponse,
  ImpersonateParams,
  GeneralAsyncThunkConfig
>(
  'auth/impersonate',
  async (params: ImpersonateParams, { rejectWithValue }) => {
    try {
      const response = await authAPI.impersonate(params);

      return {
        accessToken: response.data.accessToken,
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          return rejectWithValue({
            errorCode: ERROR_REQUEST_INVALID,
            details: error.response.data?.details,
          });
        }

        if (error.response.status === 401) {
          return rejectWithValue({ errorCode: ERROR_NOT_AUTHORIZED });
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

export const signOut = createAsyncThunk<
  SignOutResponse,
  void,
  GeneralAsyncThunkConfig
>('auth/signOut', async (_, { rejectWithValue }) => {
  try {
    const response = await authAPI.signOut();

    if (isInProtectedPage(document.location.pathname)) {
      navigate('/login');
    }

    return response.data;
  } catch (error) {
    if (isInProtectedPage(document.location.pathname)) {
      navigate('/login');
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

export const triggerResetPassword = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordParam,
  GeneralAsyncThunkConfig
>(
  'auth/resetPassword',
  async (params: ResetPasswordParam, { rejectWithValue }) => {
    try {
      const response = await authAPI.triggerResetPassword(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          return rejectWithValue({
            errorCode: ERROR_REQUEST_INVALID,
            details: error.response.data?.details,
          });
        }

        if (error.response.status === 404) {
          return rejectWithValue({
            errorCode: ERROR_RESOURCE_NOT_FOUND,
          });
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

export const resetPassword = createAsyncThunk<
  NewPasswordResponse,
  NewPasswordParams,
  GeneralAsyncThunkConfig
>(
  'auth/newPassword',
  async (params: NewPasswordParams, { rejectWithValue }) => {
    try {
      const response = await authAPI.resetPassword(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          return rejectWithValue({
            errorCode: ERROR_REQUEST_INVALID,
            details: error.response.data?.details,
          });
        }

        if (error.response.status === 403) {
          return rejectWithValue({
            errorCode: ERROR_RESOURCE_NOT_FOUND,
          });
        }
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

const initialState = {
  accessToken: '',
  username: '',
  signInAttemptId: '',
  impersonate: false,
};

const authSlice = createSlice({
  name: '@@merchant-dashboard/auth',
  initialState,
  reducers: {
    resetSigninAttempt(state) {
      return {
        ...state,
        signInAttemptId: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken || '';
      state.username = action.payload.username || '';
      state.signInAttemptId = action.payload.signInAttemptId || '';
      state.impersonate = false;
    });
    builder.addCase(signInWithOtp.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken || '';
      state.impersonate = false;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken || '';
    });
    builder.addCase(impersonate.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken || '';
      state.impersonate = true;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.accessToken = '';
      state.username = '';
      state.signInAttemptId = '';
    });
    builder.addCase(signOut.rejected, (state) => {
      state.accessToken = '';
      state.username = '';
      state.signInAttemptId = '';
    });
  },
});

export const refresh = () => () => {
  return authAPI.refresh();
};

export const { resetSigninAttempt } = authSlice.actions;
export default authSlice.reducer;
