import { MERCHANT_API_BASE } from '../constants';
import { LineItem } from '../types/order';
import axios from './api';

export type RefundParams = {
  payment: string;
  amount: number;
  currency: string;
  description?: string;
  reason: 'requested_by_customer' | 'fraudulent';
  lineItems?: LineItem[];
  metadata?: { [key: string]: number | string };
};

export type CaptureParams = {
  order: string;
  amount: number;
  currency: string;
  description?: string;
};

const paymentAPI = {
  createRefund: (refundParams: RefundParams) => {
    const { description, lineItems, metadata, ...restProps } = refundParams;

    const params = {
      ...restProps,
      ...(description && { description }),
      ...(lineItems ? { lineItems } : { lineItems: [] }),
      ...(metadata && { metadata }),
    };

    return axios.post(`${MERCHANT_API_BASE}/me/refunds`, params);
  },
  createCapture: (captureParams: CaptureParams) => {
    const { description, ...restProps } = captureParams;

    const params = {
      ...restProps,
      ...(description && { description }),
    };

    return axios.post(`${MERCHANT_API_BASE}/me/payments`, params);
  },
};

export default paymentAPI;
