import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// eslint-disable-next-line max-len
import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { isValid } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import TextInput from '../../components/Form/TextInput';
import { PROMOTION_CODE_CREATION_SCREEN } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { createPromotionCode } from '../../redux/coupon';
import { ERROR_PROMOTION_CODE_EXISTS } from '../../redux/error-codes';
import { useDataMode } from '../../utils/helper';
import styles from './CreatePromotionCodeModal.module.scss';

const emptyFn = () => {};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4456dd',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #dfe1e5', // default
            },
            '&:hover fieldset': {
              border: '1px solid #dfe1e5', // hover
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4456dd', // focus
            },
          },
        },
      },
    },
  },
});

const screen = PROMOTION_CODE_CREATION_SCREEN;

const CreatePromotionCodeModal = ({
  couponId,
  onCreatePromotionCodeSuccessful = emptyFn,
  onDismiss,
}: {
  couponId: string;
  onCreatePromotionCodeSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t, i18n } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const { test: isTestMode } = useDataMode();

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [firstTimeTransaction, setFirstTimeTransaction] = useState(false);
  const [onePerCustomer, setOnePerCustomer] = useState(false);
  const [hasMaxRedemptions, setHasMaxRedemptions] = useState(false);

  const [hasExpiryDate, setHasExpiryDate] = useState(false);
  const [hasMinimumAmount, setHasMinimumAmount] = useState<boolean>(false);
  const [minimumAmount, setMinimumAmount] = useState<number>(0);

  const [maxRedemptionCount, setMaxRedemptionCount] = useState(0);
  const [maxRedemptionCountError, setMaxRedemptionCountError] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [minimumAmountError, setMinimumAmountError] = useState<string>('');
  const [expiryDateError, setExpiryDateError] =
    useState<DateTimeValidationError | null>(null);

  return (
    <div className={styles['modal-background']}>
      <div className={cx(styles.modal, isTestMode ? styles.test : null)}>
        <div>
          <h3 id="create-promotion-code-modal-title">
            {t('create-promotion-code-modal.title')}
          </h3>
          <p>
            <Trans i18nKey="create-promotion-code-modal.desc" />
          </p>
        </div>
        <div className={styles.body}>
          <TextInput
            autoFocus
            name="promotion-code"
            label={t('create-promotion-code-modal.code.label')}
            placeholder={t('create-promotion-code-modal.code.placeholder')}
            value={code}
            onChange={(event) => {
              setCode(event.currentTarget.value);
              if (codeError !== '') {
                setCodeError('');
              }
            }}
            onBlur={(event) => {
              const { value } = event.currentTarget;

              if (value === '') {
                setCodeError(t('create-promotion-code-modal.code.is-required'));
              }
            }}
            errorMessage={codeError}
          />
          <Checkbox
            label={t(
              'create-promotion-code-modal.first-time-transaction.label'
            )}
            name="first-time-transaction"
            checked={firstTimeTransaction}
            onChange={(event) =>
              setFirstTimeTransaction(event.currentTarget.checked)
            }
          />
          <Checkbox
            label={t('create-promotion-code-modal.one-per-customer.label')}
            name="one-per-customer"
            checked={onePerCustomer}
            onChange={(event) => setOnePerCustomer(event.currentTarget.checked)}
          />
          <Checkbox
            label={t('create-promotion-code-modal.max-redemption.label')}
            name="max-redemption-count-checkbox"
            checked={hasMaxRedemptions}
            onChange={(event) =>
              setHasMaxRedemptions(event.currentTarget.checked)
            }
          />
          {hasMaxRedemptions && (
            <TextInput
              autoFocus
              name="max-redemption-count"
              rightText="回"
              value={maxRedemptionCount.toString()}
              onChange={(event) => {
                const value = Number(event.currentTarget.value);

                setMaxRedemptionCount(value);

                if (value > 0) {
                  setMaxRedemptionCountError('');
                }
              }}
              onBlur={(event) => {
                const value = Number(event.currentTarget.value);

                if (value < 1) {
                  setMaxRedemptionCountError(
                    t(
                      'create-promotion-code-modal.max-redemption.at-least-1-time'
                    )
                  );
                } else {
                  setMaxRedemptionCountError('');
                }
              }}
              errorMessage={maxRedemptionCountError}
            />
          )}

          <Checkbox
            label={t('create-promotion-code-modal.expiry-date.label')}
            name="promotion-code-deadline"
            checked={hasExpiryDate}
            onChange={(event) => setHasExpiryDate(event.currentTarget.checked)}
          />

          {hasExpiryDate && (
            <div>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={i18n.language === 'ja' ? ja : enUS}
                >
                  <DateTimePicker
                    value={expiryDate}
                    onChange={(newValue) => setExpiryDate(newValue)}
                    ampm={false}
                    minDateTime={new Date()}
                    inputFormat="yyyy/MM/dd HH:mm"
                    renderInput={(params) => (
                      <TextField autoFocus {...params} />
                    )}
                    onError={(err) => setExpiryDateError(err)}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </div>
          )}
          <Checkbox
            label={t('create-promotion-code-modal.minimum-amount.label')}
            name="minimum-amount-limitation"
            checked={hasMinimumAmount}
            onChange={(event) =>
              setHasMinimumAmount(event.currentTarget.checked)
            }
          />
          {hasMinimumAmount && (
            <TextInput
              autoFocus
              name="minimum-amount"
              rightText="JPY"
              value={minimumAmount.toString()}
              onChange={(event) => {
                const value = Number(event.currentTarget.value);

                setMinimumAmount(value);

                if (value > 0) {
                  setMinimumAmountError('');
                }
              }}
              onBlur={(event) => {
                const value = Number(event.currentTarget.value);

                if (value < 1) {
                  setMinimumAmountError(
                    t(
                      'create-promotion-code-modal.minimum-amount.at-least-1-yen'
                    )
                  );
                } else {
                  setMinimumAmountError('');
                }
              }}
              errorMessage={minimumAmountError}
            />
          )}
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="btn_create_promotion_code_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Create Promotion Code Cancel',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_create_promotion_code_confirm"
            label={t('create-promotion-code-modal.add-promotion-code-btn')}
            size="small"
            disabled={
              code.length < 1 ||
              (hasMaxRedemptions && maxRedemptionCount < 1) ||
              (hasExpiryDate && !isValid(expiryDate)) ||
              (hasExpiryDate && expiryDateError !== null) ||
              (hasMinimumAmount && minimumAmount < 1)
            }
            onClick={async () => {
              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Create Promotion Code Confirm',
              });

              const resultAction = await dispatch(
                createPromotionCode({
                  coupon: couponId,
                  code,
                  active: true,
                  test: isTestMode,
                  firstTimeTransaction,
                  onePerCustomer,
                  currency: 'jpy',
                  metadata: {},
                  ...(hasExpiryDate && { expiresAt: Number(expiryDate) }),
                  ...(hasMaxRedemptions && { maxRedemptionCount }),
                  ...(hasMinimumAmount && { minimumAmount }),
                })
              );

              if (createPromotionCode.fulfilled.match(resultAction)) {
                onCreatePromotionCodeSuccessful();
              } else {
                const { payload } = resultAction;

                switch (payload?.errorCode) {
                  case ERROR_PROMOTION_CODE_EXISTS: {
                    setCodeError(
                      t('create-promotion-code-modal.error.code-exists', {
                        code,
                      })
                    );
                    break;
                  }
                  default: {
                    setCodeError(t('error.unspecific'));
                    break;
                  }
                }

                setIsLoading(false);
                return;
              }

              onDismiss();
            }}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePromotionCodeModal;
