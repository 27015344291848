// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import japanPostalCode from 'japan-postal-code-oasis';
import debounce from 'lodash.debounce';
import React from 'react';
import { LineItemRes } from '../types/payment-link';
// eslint-disable-next-line max-len
// https://github.com/medipass/react-payment-inputs/tree/1fa8391a0c2f68baa8bf6382905c6ae10b4080e8/src/utils
import * as cardTypes from './card-types';

export const formatCardNumber = (cardNumber: string) => {
  const cardType = cardTypes.getCardTypeByValue(cardNumber);

  if (!cardType) return (cardNumber.match(/\d+/g) || []).join('');

  const { format } = cardType;

  if (format && format.global) {
    return (cardNumber.match(format) || []).join(' ');
  }

  if (format) {
    const execResult = format.exec(cardNumber.split(' ').join(''));

    if (execResult) {
      return execResult
        .splice(1, 3)
        .filter((x) => x)
        .join(' ');
    }
  }

  return cardNumber;
};

export const formatExpiry = (
  event: React.BaseSyntheticEvent<{ data: string }>
) => {
  const eventData = event.nativeEvent && event.nativeEvent.data;
  const prevExpiry = event.target.value.split(' / ').join('/');

  if (!prevExpiry) {
    return '';
  }

  let expiry = prevExpiry;

  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`;
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry.split('');

    expiry = `0${head}/${tail.join('')}`;
  }

  if (/^1[/-]$/.test(expiry)) {
    return `01 / `;
  }

  expiry = expiry.match(/(\d{1,2})/g) || [];

  if (expiry.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiry[0];
    }

    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `;
    }
  }

  if (expiry.length > 2) {
    const [, month = null, year = null] =
      expiry.join('').match(/^(\d{2}).*(\d{2})$/) || [];

    return [month, year].join(' / ');
  }

  return expiry.join(' / ');
};

const ZIP_REQ_DELAY = 250;
const ZIP_DATA_HOST = 'https://smartpay.checkit.today/static/zip/';

type ZipReqResult = {
  state: string;
  city: string;
  address1: string;
  address2: string;
};
export const debouncedZipReq = debounce(
  ({
    updateProfilePairs,
    successCallback,
    value,
  }: {
    updateProfilePairs: (result: ZipReqResult) => void;
    successCallback: (result: ZipReqResult) => void;
    value: string;
  }) => {
    try {
      japanPostalCode(value, ZIP_DATA_HOST).then(
        (address: { prefecture: string; city: string; area: string }) => {
          if (address) {
            const result = {
              state: address.prefecture,
              city: `${address.city}${address.area}`,
              address1: '',
              address2: '',
            };

            updateProfilePairs(result);
            successCallback(result);
          }
        }
      );
    } catch (error) {
      // TODO: sentry error tracking when available
    }
  },
  ZIP_REQ_DELAY
);

export const formatPhoneNumber = (phone: string): string => {
  // Filter only numbers from the input
  const cleaned = `${phone}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }

  return phone;
};

export const getLineItemsAmount = (lineItems: LineItemRes[]) => {
  return lineItems.reduce((accu, cur) => {
    let _accu = accu;

    return (_accu += cur.quantity * cur.price.amount);
  }, 0);
};

export const currencyFormatter = (n: number) =>
  new Intl.NumberFormat('ja-JP').format(n);

export const isURL = (url: string) => {
  const expression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

  return expression.test(url);
};
