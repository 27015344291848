import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import { useAccountInfoQuery } from '../../services/setting';
import styles from './AccountsScreen.module.scss';
import SettingNavBar from './SettingNavBar';
import Time from '../../components/Time/Time';

const AccountsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');
  const { data: accountInfo } = useAccountInfoQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const legalEntity = accountInfo?.legalEntity;

  return (
    <Layout title={t('settings.title')} className={styles.layout}>
      <SettingNavBar />
      <div className={styles.container}>
        <section>
          <h2>{t('settings.accounts.contact-info.title')}</h2>
          <p className={styles.contract}>
            {t('settings.accounts.contact-info.contract-start-at')}
            <br />
            <Time
              dateTime={accountInfo?.contractedStartAt}
              onEmpty={t('settings.accounts.contact-info.to-be-decided')}
              formatStr={t('format.yyyyMMMddHHmm', 'yyyy/MM/dd HH:mm')}
            />
          </p>
          <TextInput
            label={t('settings.accounts.contact-info.name')}
            variant="secondary"
            name="name"
            value={accountInfo?.name}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.contact-email')}
            variant="secondary"
            name="email"
            value={accountInfo?.emailAddress}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.phone-number')}
            variant="secondary"
            name="phone_number"
            value={accountInfo?.phoneNumber ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.last-name')}
            variant="secondary"
            name="last_name"
            value={accountInfo?.legalEntity?.representative?.lastName ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.first-name')}
            variant="secondary"
            name="first_name"
            value={legalEntity?.representative?.firstName ?? ''}
            className={styles['col-2']}
            readOnly
          />
          {accountInfo?.country === 'jp' && (
            <>
              <TextInput
                label={t('settings.accounts.contact-info.last-name-kata')}
                variant="secondary"
                name="last_name_kata"
                value={legalEntity?.representative?.lastNameKana ?? ''}
                className={styles['col-2']}
                readOnly
              />
              <TextInput
                label={t('settings.accounts.contact-info.first-name-kata')}
                variant="secondary"
                name="fist_name_kata"
                value={legalEntity?.representative?.firstNameKana ?? ''}
                className={styles['col-2']}
                readOnly
              />
            </>
          )}
        </section>
        <section>
          <h2>{t('settings.accounts.legal-info.title')}</h2>
          <TextInput
            label={t('settings.accounts.legal-info.corporate-number')}
            variant="secondary"
            name="corporate_number"
            value={legalEntity?.corporateNumber ?? ''}
            readOnly
          />

          <TextInput
            label={t('settings.accounts.legal-info.company-name')}
            variant="secondary"
            name="company_name"
            value={legalEntity?.name ?? ''}
            readOnly
          />
          {accountInfo?.country === 'jp' && (
            <TextInput
              label={t('settings.accounts.legal-info.company-name-kata')}
              variant="secondary"
              name="company_name_kana"
              value={legalEntity?.nameKana ?? ''}
              readOnly
            />
          )}
          <TextInput
            label={t('settings.accounts.legal-info.postal-code')}
            variant="secondary"
            name="postal_code"
            leftIcon="zip"
            value={legalEntity?.address?.postalCode ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.prefecture')}
            variant="secondary"
            name="prefecture"
            value={legalEntity?.address?.administrativeArea ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.city-ward-locality')}
            variant="secondary"
            name="locality"
            value={legalEntity?.address.locality ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.number')}
            variant="secondary"
            name="number"
            value={legalEntity?.address.line1 ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.building-name-room-number')}
            variant="secondary"
            name="building_name"
            value={legalEntity?.address.line2 ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.website')}
            variant="secondary"
            name="website"
            value={legalEntity?.website ?? ''}
            readOnly
          />
        </section>
      </div>
      <p className={styles.contact}>
        <Trans
          i18nKey="settings.accounts.contact-support-desc"
          components={{
            supportLink: (
              <a
                href="mailto:support@smartpay.co"
                onClick={() =>
                  Mixpanel.trackAction({
                    action: 'Click',
                    itemName: 'Contact our support',
                  })
                }
              >
                Contact our support
              </a>
            ),
          }}
        />
      </p>
    </Layout>
  );
};

export default AccountsScreen;
