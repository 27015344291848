import cx from 'classnames';
import { isReachAmountLimit } from '../../utils/helper';
import styles from './RedemptionView.module.scss';

const RedemptionView = ({
  redemptionCount,
  maxRedemptionCount,
}: {
  redemptionCount: number;
  maxRedemptionCount?: number;
}) => {
  const isFinished = isReachAmountLimit({
    redemptionCount,
    maxRedemptionCount,
  });

  return (
    <div
      className={cx(
        styles['redemption-container'],
        isFinished ? styles.finished : ''
      )}
    >
      <i>{redemptionCount}</i>
      {maxRedemptionCount && maxRedemptionCount > 0 ? (
        <i>/{maxRedemptionCount}</i>
      ) : null}
    </div>
  );
};

export default RedemptionView;
