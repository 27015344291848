import { formatCurrency } from '../../utils/helper';
import styles from './FeeView.module.scss';

const FeeView = ({
  fee = 0,
  fixedFee = 0,
  desc = '',
  currency = 'JPY',
  hidden = false,
}: {
  fee?: number;
  fixedFee?: number;
  desc?: string;
  currency?: string;
  hidden?: boolean;
}) => {
  if (hidden) {
    return <div className={styles['fee-view-container']} />;
  }

  return (
    <div className={styles['fee-view-container']}>
      <p>
        <strong>{fee * 100}%</strong>
        {fixedFee > 0 && (
          <>
            <i>+</i>{' '}
            <span className={styles['fixed-fee']}>
              {formatCurrency(fixedFee, currency)}
            </span>
          </>
        )}
      </p>
      {desc && <p className={styles.desc}>{desc}</p>}
    </div>
  );
};

export default FeeView;
