// https://www.npmjs.com/package/flagon

export interface Flagon {
  contains: (B: number) => boolean;
  merge: (B: number) => Flagon;
  toggle: (B: number) => Flagon;
  value: () => number;
  toString: (B: number) => string;
}

const flagon = (A: number): Flagon =>
  ({
    contains: (B: number) => flagon.contains(A, B),
    merge: (B: number) => flagon(flagon.merge(A, B)),
    toggle: (B: number) => flagon(flagon.toggle(A, B)),
    value: () => A,
    toString: () => A.toString(2),
  } as Flagon);

flagon.contains = (A: number, B: number) => B == 0 || (A & B) == B; // eslint-disable-line
flagon.merge = (A: number, B: number) => A | B; // eslint-disable-line
flagon.toggle = (A: number, B: number) => A ^ B; // eslint-disable-line

export default flagon;
