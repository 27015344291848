import { Link, navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, SyntheticEvent, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logotype.svg';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import TextInput from '../../components/Form/TextInput';
import {
  FORM_STATE_PENDING,
  FORM_STATE_PROCESSING,
  FORM_STATE_SUCCESS,
  LOGIN_SCREEN,
} from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { impersonate, signIn } from '../../redux/auth';
import {
  ERROR_NOT_AUTHORIZED,
  ERROR_REQUEST_INVALID,
  ERROR_UNEXPECTED_ERROR,
} from '../../redux/error-codes';
import { updateMiscPairs } from '../../redux/misc';
import { MsgMapping } from '../../types';
import createEventHandler from '../../utils/create-event-handler';
import styles from './LoginScreen.module.scss';
import { useLazyRoleQuery } from '../../services/setting';
import { prepareRoleHookReturns } from '../../hooks/use-role';
import useAppSelector from '../../hooks/use-app-selector';

const ERROR_MSG_MAPPING: MsgMapping = {
  [ERROR_NOT_AUTHORIZED]: 'login.error.username-password-not-match',
};

const LoginScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [formState, setFormState] = useState(FORM_STATE_PENDING);
  const [errorCode, setErrorCode] = useState('');
  const oneTimeToken = useAppSelector((state) => state.misc.oneTimeToken);
  const [queryRole] = useLazyRoleQuery();

  const onSubmit = createEventHandler(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formState === FORM_STATE_PROCESSING) {
        return;
      }

      setFormState(FORM_STATE_PROCESSING);

      dispatch(
        updateMiscPairs({
          rememberMe,
        })
      );

      const resultAction = await dispatch(signIn({ username, password }));

      if (signIn.fulfilled.match(resultAction)) {
        setFormState(FORM_STATE_SUCCESS);

        if (resultAction?.payload?.accessToken) {
          const { data } = await queryRole(btoa(username));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, { homepage }] = prepareRoleHookReturns(data);

          navigate(homepage);
        } else if (resultAction?.payload?.signInAttemptId) {
          navigate('/2fa');
        } else {
          setErrorCode(ERROR_UNEXPECTED_ERROR);
        }
      } else {
        const responseErrorCode = resultAction?.payload?.errorCode;

        setFormState(FORM_STATE_PENDING);

        if (
          responseErrorCode === ERROR_REQUEST_INVALID ||
          responseErrorCode === ERROR_NOT_AUTHORIZED
        ) {
          setErrorCode(ERROR_NOT_AUTHORIZED);

          return;
        }

        setErrorCode(ERROR_UNEXPECTED_ERROR);
      }
    }
  );

  useLayoutEffect(() => {
    (async () => {
      if (oneTimeToken) {
        dispatch(updateMiscPairs({ oneTimeToken: '' }));

        const resultAction = await dispatch(impersonate({ oneTimeToken }));

        if (impersonate.fulfilled.match(resultAction)) {
          navigate('/orders');
        }
      }
    })();
  }, [dispatch, oneTimeToken]);

  return (
    <div className={styles.container}>
      <h1>
        <img src={logo} height={55} alt="Smartpay" />
      </h1>
      <form className={styles.form} onSubmit={onSubmit}>
        <fieldset>
          <TextInput
            name="username"
            type="email"
            value={username}
            aria-label={t('login.username.label')}
            placeholder={t('login.username.placeholder')}
            autoComplete="email"
            onChange={(event) => {
              setUsername(event.currentTarget.value);
            }}
          />
          <TextInput
            name="password"
            type="password"
            value={password}
            aria-label={t('login.password.label')}
            placeholder={t('login.password.placeholder')}
            autoComplete="off"
            onChange={(event) => {
              setPassword(event.currentTarget.value);
            }}
            errorMessage={
              errorCode && t(ERROR_MSG_MAPPING[errorCode] || errorCode)
            }
          />
        </fieldset>
        <Button
          id="btn_submit"
          type="submit"
          label={t('login.login-btn')}
          processing={formState === FORM_STATE_PROCESSING}
          disabled={!username || !password}
          onClick={() =>
            Mixpanel.trackAction({
              action: 'Click',
              screen: LOGIN_SCREEN,
              itemName: 'Submit',
            })
          }
        />
        <Checkbox
          label={t('login.remember-this-device')}
          name="rememberMe"
          value="1"
          checked={rememberMe}
          onChange={(event) => setRememberMe(event.currentTarget.checked)}
          className={styles['remember-me']}
        />
        <Link
          to="/reset-password"
          className={styles['forgot-password']}
          onClick={() =>
            Mixpanel.trackAction({
              action: 'Click',
              screen: LOGIN_SCREEN,
              itemName: 'Forgot Password',
            })
          }
        >
          {t('login.forget-password-url-desc')}
        </Link>
      </form>
      <Footer />
    </div>
  );
};

export default LoginScreen;
