import axios from 'axios';
import { MERCHANT_API_BASE } from '../constants';
import baseAPI from '../services/base';
import authedAxios from './api';

export type SignInParams = {
  username: string;
  password: string;
};
export type ResendOTPParams = {
  signInAttemptId: string;
  otpDispatchMethod: string;
};
export type SignInWithOtpParams = {
  signInAttemptId: string;
  otpSecret: string;
};
export type ResetPasswordParam = {
  username: string;
};
export type NewPasswordParams = {
  token: string;
  newPassword: string;
};
export type ImpersonateParams = {
  oneTimeToken: string;
};

const authAPI = {
  signIn: ({ username, password }: SignInParams) => {
    return axios.post(
      `${MERCHANT_API_BASE}/auth/login`,
      { username, password },
      {
        withCredentials: true,
      }
    );
  },
  resendOtp: ({ signInAttemptId }: ResendOTPParams) => {
    return axios.post(
      `${MERCHANT_API_BASE}/auth/otp-resend`,
      { signinAttemptId: signInAttemptId },
      {
        withCredentials: true,
      }
    );
  },
  signInWithOtp: ({ signInAttemptId, otpSecret }: SignInWithOtpParams) => {
    return axios.put(
      `${MERCHANT_API_BASE}/auth/login`,
      { signinAttemptId: signInAttemptId, otpSecret },
      {
        withCredentials: true,
      }
    );
  },
  impersonate: ({ oneTimeToken }: ImpersonateParams) => {
    return axios.post(
      `${MERCHANT_API_BASE}/impersonations`,
      { oneTimeToken },
      {
        withCredentials: true,
      }
    );
  },
  signOut: () => {
    // cleanup RTKQ cache
    baseAPI.util.resetApiState();

    return authedAxios.post(`${MERCHANT_API_BASE}/auth/logout`, null, {
      withCredentials: true,
    });
  },
  refresh: () =>
    axios.post(`${MERCHANT_API_BASE}/auth/refresh`, null, {
      withCredentials: true,
    }),
  triggerResetPassword: ({ username }: ResetPasswordParam) =>
    axios.post(`${MERCHANT_API_BASE}/password-resets`, {
      username,
    }),
  resetPassword: ({ token, newPassword }: NewPasswordParams) =>
    axios.put(`${MERCHANT_API_BASE}/password-resets/${token}`, { newPassword }),
};

export default authAPI;
