import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, SyntheticEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import logo from '../../assets/logotype.svg';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import {
  FORM_STATE_PENDING,
  FORM_STATE_PROCESSING,
  FORM_STATE_SUCCESS,
} from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { triggerResetPassword } from '../../redux/auth';
import {
  ERROR_RESOURCE_NOT_FOUND,
  ERROR_UNEXPECTED_ERROR,
} from '../../redux/error-codes';
import { MsgMapping } from '../../types';
import createEventHandler from '../../utils/create-event-handler';
import styles from './ResetPasswordScreen.module.scss';

const ERROR_MSG_MAPPING: MsgMapping = {
  [ERROR_RESOURCE_NOT_FOUND]: 'reset-password.error.user-not-exists',
};

const ResetPasswordScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation');
  const [username, setUsername] = useState('');
  const [formState, setFormState] = useState(FORM_STATE_PENDING);
  const [errorCode, setErrorCode] = useState('');

  const onSubmit = createEventHandler(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formState === FORM_STATE_PROCESSING) {
        return;
      }

      setFormState(FORM_STATE_PROCESSING);

      const resultAction = await dispatch(triggerResetPassword({ username }));

      if (triggerResetPassword.fulfilled.match(resultAction)) {
        setFormState(FORM_STATE_SUCCESS);
      } else {
        const responseErrorCode = resultAction?.payload?.errorCode;

        setFormState(FORM_STATE_PENDING);

        if (responseErrorCode === ERROR_RESOURCE_NOT_FOUND) {
          setErrorCode(ERROR_RESOURCE_NOT_FOUND);

          return;
        }

        setErrorCode(ERROR_UNEXPECTED_ERROR);
      }
    }
  );

  return (
    <div className={styles.container}>
      <h1>
        <img src={logo} height={55} alt="Smartpay" />
      </h1>

      {formState === FORM_STATE_SUCCESS ? (
        <div className={styles.content}>
          <Trans
            i18nKey="reset-password.reset-password-link-sent"
            components={{
              title: <h2>title</h2>,
              note: <p className={styles.note}>content</p>,
              btn: <button type="button">resend</button>,
            }}
          />
        </div>
      ) : (
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.content}>
            <h2>{t('reset-password.title')}</h2>
            <p>{t('reset-password.desc')}</p>
          </div>
          <fieldset>
            <TextInput
              name="username"
              type="email"
              value={username}
              onChange={(event) => setUsername(event.currentTarget.value)}
              label={t('reset-password.username.label')}
              aria-label={t('reset-password.username.label')}
              placeholder={t('reset-password.username.placeholder')}
              autoComplete="email"
              errorMessage={
                errorCode && t(ERROR_MSG_MAPPING[errorCode] || errorCode)
              }
            />
          </fieldset>
          <Button
            type="submit"
            label={t('continue-btn')}
            processing={formState === FORM_STATE_PROCESSING}
            disabled={!username}
            onClick={() =>
              Mixpanel.trackAction({
                action: 'Click',
                itemName: 'Submit',
              })
            }
          />
        </form>
      )}
      <Footer />
    </div>
  );
};

export default ResetPasswordScreen;
