import { DEFAULT_PAGE_SIZE, MERCHANT_API_BASE } from '../constants';
import { PageParams, TestParams } from '../services/base';
import { OrderStatus } from '../types/order';
import axios from './api';

export interface OrderParams extends TestParams, PageParams {
  q?: string;
  status?: OrderStatus;
}

const defaultParams = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

const orderAPI = {
  getOrders: (orderParams: OrderParams) =>
    axios.get(`${MERCHANT_API_BASE}/me/orders`, {
      params: {
        ...defaultParams,
        ...orderParams,
      },
    }),
  getOrder: (orderId: string) =>
    axios.get(`${MERCHANT_API_BASE}/me/orders/${orderId}`),
  cancelOrder: (orderId: string) =>
    axios.put(`${MERCHANT_API_BASE}/me/orders/${orderId}/cancellation`),
};

export default orderAPI;
