import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Layout from '../../components/Layout';
import styles from './InquiryScreen.module.scss';
import Button from '../../components/Form/Button';
import { useAccountInfoQuery } from '../../services/setting';

type InquiryCategory = {
  id: string;
  i18nKey: string;
  extraFields: string[];
  hideFields?: string[];
};
type InquiryState = 'inquiring' | 'previewing' | 'submitting' | 'submitted';

type InquiryFormInput = {
  inquiryCategory: string;
  name: string;
  nameYomikata: string;
  email: string;
  phone: string;
  orderId: string;
  website: string;
  inquiry: string;
};

const INQUIRY_CATEGORIES: InquiryCategory[] = [
  { id: 'payout', i18nKey: 'inquiry-cat-payout', extraFields: ['website'] },
  {
    id: 'dashboard',
    i18nKey: 'inquiry-cat-dashboard',
    extraFields: ['website'],
  },
  {
    id: 'order',
    i18nKey: 'inquiry-cat-order',
    extraFields: ['website', 'orderId'],
  },
  {
    id: 'cancel-and-refund',
    i18nKey: 'inquiry-cat-cancel-and-refund',
    extraFields: ['website', 'orderId'],
  },
  { id: 'system-error', i18nKey: 'inquiry-cat-system-error', extraFields: [] },
  {
    id: 'merchant-other',
    i18nKey: 'inquiry-cat-other',
    extraFields: ['website'],
  },
];

const MERCHANT_INQUIRY_CATEGORIES = [
  'payout',
  'dashboard',
  'order',
  'cancel-and-refund',
  'system-error',
  'merchant-other',
];

const InquiryScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t, i18n } = useTranslation('translation');
  const [error, setError] = useState('');
  const [inquiryState, setInquiryState] = useState<InquiryState>('inquiring');
  const { data: accountInfo } = useAccountInfoQuery();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      inquiryCategory: MERCHANT_INQUIRY_CATEGORIES[0],
      name: '',
      nameYomikata: '',
      email: accountInfo?.userEmailAddress || '',
      phone: '',
      orderId: '',
      website: '',
      inquiry: '',
    },
  });

  const inquiryCategory = watch('inquiryCategory');
  const inquiryCategoryData = INQUIRY_CATEGORIES.find(
    (item) => item.id === inquiryCategory
  );
  const extraFields = inquiryCategoryData?.extraFields || [];
  const hideFields = inquiryCategoryData?.hideFields || [];
  const inquiry = watch('inquiry');

  const onSubmitInquiry = (data: InquiryFormInput) => {
    setInquiryState('submitting');
    setError('');

    const endpoint =
      process.env.REACT_APP_SUPPORT_INQUIRY_URL ||
      'https://smartpay.co/api/support/inquiry/form';
    const payload = {
      ...data,
      submitterCategory: 'merchant',
      fromDashboard: '1',
    };

    axios
      .post(endpoint, payload)
      .then(() => {
        setInquiryState('submitted');
      })
      .catch(() => {
        setInquiryState('previewing');
        setError('unexpected_error');
      });
  };

  return (
    <Layout className={styles.layout}>
      <div className={styles.container}>
        {inquiryState === 'inquiring' && (
          <section className={styles.contact}>
            <h2>
              <Trans i18nKey="contact-us-title" />
            </h2>
            <p className={styles.desc}>
              <Trans i18nKey="contact-us-description" />
            </p>
            <form
              className={styles.form}
              onSubmit={handleSubmit(() => setInquiryState('previewing'))}
            >
              <div className={styles.fields}>
                <div className={styles.row}>
                  <div className={styles.field}>
                    <label htmlFor="name">
                      {t('contact-form-name')}{' '}
                      <span className={styles.required}>*</span>
                    </label>
                    <input
                      id="name"
                      placeholder="須磨 愛里"
                      required
                      {...register('name', { required: true })}
                    />
                  </div>
                  {i18n.language === 'ja' && (
                    <div className={styles.field}>
                      <label htmlFor="name">
                        {t('contact-form-name-yomikata')}{' '}
                        <span className={styles.required}>*</span>
                      </label>
                      <input
                        id="name_yomikata"
                        placeholder="すま あいり"
                        required
                        {...register('nameYomikata', { required: true })}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.field}>
                <label htmlFor="email">
                  {t('contact-form-email')}{' '}
                  <span className={styles.required}>*</span>
                </label>
                <div className={styles.row}>
                  <input
                    type="email"
                    id="email"
                    placeholder="mail@mail.com"
                    required
                    readOnly
                    {...register('email', { required: true })}
                  />
                </div>
              </div>
              {!hideFields.includes('category') && (
                <div className={styles.field}>
                  <label htmlFor="inquiry-category">
                    {t('contact-form-inquiry-category')}{' '}
                    <span className={styles.required}>*</span>
                  </label>
                  <select
                    id="inquiry-category"
                    {...register('inquiryCategory', { required: true })}
                  >
                    {MERCHANT_INQUIRY_CATEGORIES.map((key) => {
                      const option = INQUIRY_CATEGORIES.find(
                        (item) => item.id === key
                      );

                      if (!option) {
                        return null;
                      }

                      return (
                        <option
                          value={option.id}
                          // selected={inquiryCategory === option.id}
                          key={key}
                        >
                          {t(option.i18nKey)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {extraFields.includes('phone') && (
                <div className={styles.field}>
                  <label htmlFor="phone">
                    {t('contact-form-phone')}{' '}
                    <span className={styles.required}>*</span>
                  </label>
                  <input
                    id="phone"
                    placeholder="08012345678"
                    required
                    {...register('phone', { required: true })}
                  />
                </div>
              )}
              {extraFields.includes('website') && (
                <div className={styles.field}>
                  <label htmlFor="website">
                    {t('contact-form-website-name')}{' '}
                    <span className={styles.required}>*</span>
                  </label>
                  <input
                    id="website"
                    placeholder="Your Online Shop"
                    required
                    {...register('website', { required: true })}
                  />
                </div>
              )}
              {extraFields.includes('orderId') && (
                <div className={styles.field}>
                  <label htmlFor="order_id">
                    <Trans
                      i18nKey="contact-form-order-id"
                      components={{ span: <span /> }}
                    />{' '}
                    <span className={styles.required}>*</span>
                  </label>
                  <input
                    id="order_id"
                    placeholder="order_live_XXXXXXXXXXXXXXXXXXXXXX"
                    required
                    {...register('orderId', { required: true })}
                  />
                </div>
              )}
              <div className={styles.field}>
                <label htmlFor="inquiry">
                  {t(
                    inquiryCategory === 'system-error'
                      ? 'contact-form-system-error-inquiry'
                      : 'contact-form-inquiry'
                  )}{' '}
                  <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="inquiry"
                  placeholder={t('contact-form-inquiry-placeholder')}
                  required
                  {...register('inquiry', { required: true })}
                />
              </div>
              <div className={styles.actions}>
                <Button
                  type="submit"
                  disabled={!isValid}
                  className={styles.action}
                  label={t('to-confirm')}
                />
              </div>
            </form>
          </section>
        )}
        {['previewing', 'submitting'].includes(inquiryState) && (
          <section className={styles.contact}>
            <div id="contact_us" className={styles.contactUs}>
              <div className={styles.content}>
                <h2>
                  <Trans i18nKey="contact-us-preview-title" />
                </h2>
                <p className={styles.desc}>
                  <Trans i18nKey="contact-us-preview-description" />
                </p>

                <form
                  className={styles.preview}
                  onSubmit={handleSubmit(onSubmitInquiry)}
                >
                  <div className={styles.fields}>
                    <div className={styles.row}>
                      <div className={styles.field}>
                        <label htmlFor="name">{t('contact-form-name')}</label>
                        <input
                          id="name"
                          readOnly
                          {...register('name', { required: true })}
                        />
                      </div>
                      {i18n.language === 'ja' && (
                        <div className={styles.field}>
                          <label htmlFor="name">
                            {t('contact-form-name-yomikata')}
                          </label>
                          <input
                            id="name_yomikata"
                            readOnly
                            {...register('nameYomikata', { required: true })}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="email">{t('contact-form-email')}</label>
                    <div className={styles.row}>
                      <input
                        type="email"
                        id="email"
                        readOnly
                        {...register('email', { required: true })}
                      />
                    </div>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="inquiry-category">
                      {t('contact-form-inquiry-category')}
                    </label>
                    <input
                      id="inquiry-category"
                      readOnly
                      value={t(inquiryCategoryData?.i18nKey || '')}
                    />

                    <input
                      id="inquiry-category"
                      type="hidden"
                      readOnly
                      {...register('inquiryCategory', { required: true })}
                    />
                  </div>
                  {extraFields.includes('phone') && (
                    <div className={styles.field}>
                      <label htmlFor="phone">{t('contact-form-phone')}</label>
                      <input
                        id="phone"
                        readOnly
                        {...register('phone', { required: true })}
                      />
                    </div>
                  )}
                  {extraFields.includes('website') && (
                    <div className={styles.field}>
                      <label htmlFor="website">
                        {t('contact-form-website-name')}
                      </label>
                      <input
                        id="website"
                        readOnly
                        {...register('website', { required: true })}
                      />
                    </div>
                  )}
                  {extraFields.includes('orderId') && (
                    <div className={styles.field}>
                      <label htmlFor="order_id">
                        <Trans
                          i18nKey="contact-form-order-id"
                          components={{ span: <span /> }}
                        />
                      </label>
                      <input
                        id="order_id"
                        readOnly
                        {...register('orderId', { required: true })}
                      />
                    </div>
                  )}

                  <div className={styles.field}>
                    <label htmlFor="inquiry">
                      {t(
                        inquiryCategory === 'system-error'
                          ? 'contact-form-system-error-inquiry'
                          : 'contact-form-inquiry'
                      )}
                    </label>
                    <div className={styles.textarea}>
                      {inquiry
                        .split('\n')
                        .reduce<Array<ReactNode | string>>(
                          (accum, line) => [...accum, line, <br />],
                          []
                        )}
                    </div>
                    <textarea
                      id="inquiry"
                      readOnly
                      {...register('inquiry', { required: true })}
                    />
                  </div>
                  <div className={styles.actions}>
                    <Button
                      type="button"
                      variant="outline"
                      label={t('edit')}
                      className={styles.action}
                      disabled={isSubmitting}
                      processing={isSubmitting}
                      onClick={() => {
                        setInquiryState('inquiring');
                      }}
                    />
                    <Button
                      type="submit"
                      disabled={!isValid}
                      processing={isSubmitting}
                      className={styles.action}
                      label={t('send')}
                    />
                  </div>
                  {error && (
                    <div className={styles['error-wrapper']}>
                      <p id="error_message">{t('error.unexpected')}</p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </section>
        )}
        {inquiryState === 'submitted' && (
          <section className={styles.contact}>
            <div id="contact_us" className={styles.contactUs}>
              <div className={styles.content}>
                <h2>
                  <Trans i18nKey="contact-us-submitted-title" />
                </h2>
                <p className={styles.desc}>
                  <Trans i18nKey="contact-us-submitted-description" />
                </p>
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default InquiryScreen;
