import Mixpanel from '@smartpay/mixpanel';
import { useCallback, useEffect, useState } from 'react';
import Button from './Button';
import styles from './OtpResendButton.module.scss';
import { useTranslation } from 'react-i18next';
import IconClock from '../../assets/icon-clock.svg';

type ResendAction = () => void;

const RESEND_THROTTLE_SECONDS = 30;
const MSG_APPEAR_LIFETIME = 5 * 1000;
const TICK = 100;

const now = () => new Date().getTime();
const padZero = (v: number | string) => {
  const str = v.toString();

  if (str.length === 0) {
    return '00';
  }
  if (str.length === 1) {
    return `0${str}`;
  }
  return str;
};

const useResendButton = (onClick: ResendAction) => {
  const [resendCountDown, setResendCountDown] = useState(
    RESEND_THROTTLE_SECONDS
  );
  const [shouldDisplayOtpSentMsg, setShouldDisplayOtpSentMsg] = useState(false);
  const [shouldDisplayOtpResendBtn, setShouldDisplayOtpResendBtn] =
    useState(false);

  const resetCoutDown = useCallback(() => {
    setResendCountDown(RESEND_THROTTLE_SECONDS);

    const startTime = now();
    const tick = () => {
      const currentTime = now();
      const delta = currentTime - startTime;
      const deltaSecond = Math.floor(delta / 1000);
      const safeDeltaSecond = Math.min(deltaSecond, RESEND_THROTTLE_SECONDS);
      const currentCountDown = RESEND_THROTTLE_SECONDS - safeDeltaSecond;

      setResendCountDown(currentCountDown);

      if (currentCountDown) {
        setTimeout(tick, TICK);
      } else {
        setShouldDisplayOtpResendBtn(true);
      }
    };

    setTimeout(tick, TICK);
  }, []);

  const onResend = useCallback(async () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Resend OTP',
    });

    await onClick();

    setShouldDisplayOtpResendBtn(false);
    setShouldDisplayOtpSentMsg(true);

    setTimeout(() => {
      setShouldDisplayOtpSentMsg(false);
      resetCoutDown();
    }, MSG_APPEAR_LIFETIME);
  }, [onClick, resetCoutDown]);

  useEffect(() => {
    resetCoutDown();
  }, [resetCoutDown]);

  return {
    resendCountDown,
    shouldDisplayOtpResendBtn,
    shouldDisplayOtpSentMsg,
    onResend,
  };
};

export default function OtpResendButton({
  onClick,
}: {
  onClick: ResendAction;
}) {
  const { t } = useTranslation('translation');

  const { resendCountDown, shouldDisplayOtpResendBtn, onResend } =
    useResendButton(onClick);

  return (
    <>
      {shouldDisplayOtpResendBtn && (
        <Button
          label={t('login.resend-otp')}
          variant="secondary"
          iconType="info"
          onClick={onResend}
          className={styles.button}
        />
      )}
      {resendCountDown > 0 && (
        <div className={styles['resend-countdown']}>
          <div>{t('login.wait-for-resend-otp')}</div>
          <div className={styles.mono}>
            <img
              className={styles['icon-clock']}
              src={IconClock}
              width={16}
              height={16}
              alt=""
              loading="eager"
            />
            00:{padZero(resendCountDown)}
          </div>
        </div>
      )}
    </>
  );
}
