import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import { COUPONS_SCREEN } from '../../constants';
import { Coupon } from '../../types/coupon';
import { couponDetails, getCouponCondition } from '../../utils/helper';
import RedemptionView from '../CouponDetailScreen/RedemptionView';
import styles from './CouponRow.module.scss';
import Time from '../../components/Time/Time';

const CouponRow = ({ coupon }: { coupon: Coupon }) => {
  const { t } = useTranslation('translation');
  const { isFinished, isExpired, isEnded } = couponDetails(coupon);

  return (
    <tr
      className={styles.coupon}
      onClick={() => {
        Mixpanel.trackAction({
          screen: COUPONS_SCREEN,
          action: 'Click',
          itemName: 'Coupon Item',
        });
        navigate(`/coupons/${coupon.id}`);
      }}
    >
      <td className={cx(styles.name, !coupon.active ? styles.inactive : null)}>
        {coupon.name}
        {coupon.sponsored && (
          <Capsule title="BY SMARTPAY" size="small" variant="sponsored" />
        )}
        {!coupon.active && (
          <Capsule
            title={t('coupon.status.invalid')}
            size="small"
            variant="invalid"
          />
        )}
      </td>
      <td className={styles.condition}>{getCouponCondition(coupon)}</td>
      <td
        className={cx(styles.redemption, isFinished ? styles.finished : null)}
      >
        {isEnded && (
          <Capsule
            title={t('coupon.status.finished')}
            size="small"
            color="cerise"
          />
        )}
        <span>
          <RedemptionView
            redemptionCount={coupon.redemptionCount}
            maxRedemptionCount={coupon.maxRedemptionCount}
          />
        </span>
      </td>
      <td>
        <Time
          dateTime={coupon.expiresAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
          className={cx(isExpired ? styles.expired : '')}
        />
      </td>
    </tr>
  );
};

export default CouponRow;
