import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import paymentAPI, { CaptureParams, RefundParams } from '../api/payment';
import { ErrorDetail } from './auth';
import {
  ERROR_AMOUNT_INSUFFICIENT,
  ERROR_AUTHORIZATION_FAILURE,
  ERROR_PAYMENT_NOTFOUND,
  ERROR_UNEXPECTED_ERROR,
} from './error-codes';

const initialState: {} = {};

type CreateRefundResponse = {};
type CreateCaptureResponse = {};

type GeneralAsyncThunkConfig = {
  rejectValue: {
    errorCode: string;
    message?: string;
    details?: ErrorDetail[];
  };
};

export const createRefund = createAsyncThunk<
  CreateRefundResponse,
  RefundParams,
  GeneralAsyncThunkConfig
>('refunds/createRefund', async (params: RefundParams, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.createRefund(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { payload } = error.response.data;

      switch (payload.errorCode) {
        case ERROR_PAYMENT_NOTFOUND:
        case ERROR_AMOUNT_INSUFFICIENT:
        case ERROR_AUTHORIZATION_FAILURE:
        case ERROR_UNEXPECTED_ERROR: {
          return rejectWithValue(payload);
        }
        default: {
          break;
        }
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

export const createCapture = createAsyncThunk<
  CreateCaptureResponse,
  CaptureParams,
  GeneralAsyncThunkConfig
>('payments/capture', async (params: CaptureParams, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.createCapture(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { payload } = error.response.data;

      switch (payload.errorCode) {
        case ERROR_UNEXPECTED_ERROR: {
          return rejectWithValue(payload);
        }
        default: {
          break;
        }
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

const settingSlice = createSlice({
  name: '@@merchant-dashboard/payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRefund.fulfilled, () => {});
    builder.addCase(createCapture.fulfilled, () => {});
  },
});

export default settingSlice.reducer;
