import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import logo from '../../assets/logotype.svg';
import verticalLine from '../../assets/vertical-line.svg';
import Button from '../../components/Form/Button';
import { ERROR_SCREEN } from '../../constants';
import styles from './ErrorScreen.module.scss';

const screen = ERROR_SCREEN;

const ErrorScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  return (
    <div className={styles.container}>
      <h1>
        <img src={logo} height={82} alt="Smartpay" />
      </h1>
      <div>
        <span>404</span>
        <img src={verticalLine} width={32} height={44} alt="" />
        <span>ページが見つかりませんでした</span>
      </div>
      <Button
        label="トップページに戻る"
        onClick={() => {
          Mixpanel.trackAction({
            screen,
            action: 'Click',
            itemName: 'Go to top page',
          });

          navigate('/');
        }}
      />
    </div>
  );
};

export default ErrorScreen;
