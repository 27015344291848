import { MERCHANT_API_BASE } from '../constants';
import axios from './api';

const settingAPI = {
  getApiKeys: () => axios.get(`${MERCHANT_API_BASE}/me/api-keys`),
  getBankAccounts: () => axios.get(`${MERCHANT_API_BASE}/me/bank-accounts`),
  getFeePlans: () => axios.get(`${MERCHANT_API_BASE}/me/fee-plans`),
  getCurrentPayoutSchedule: () =>
    axios.get(`${MERCHANT_API_BASE}/me/payout-schedules/current`),
  getAccountInfo: () => axios.get(`${MERCHANT_API_BASE}/me`),
};

export default settingAPI;
