import cx from 'classnames';
import React, { forwardRef, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import IconChecked from '../../assets/icon-checkbox-checked.svg';
import IconNotChecked from '../../assets/icon-checkbox-not-checked.svg';
import styles from './Checkbox.module.scss';

const emptyFn = () => {};

export type IconType = 'checked' | 'not-checked';

const getIconSrc = (type: IconType) => {
  switch (type) {
    case 'checked':
      return IconChecked;
    case 'not-checked':
      return IconNotChecked;
    default:
      return undefined;
  }
};

export interface CheckboxProps {
  label: string;
  'aria-label'?: string;
  placeholder?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  checked?: boolean;
  readOnly?: boolean;
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
  wrapperStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  name: string;
  className?: string;
  updatePairs?: Function;
  errorMessage?: string;
  ref?: RefCallBack;
}

const Icon = ({
  iconType,
  width = 24,
}: {
  iconType: IconType;
  width?: number;
}) => {
  const height = width;

  return (
    <img
      className={cx(styles.icon, styles[iconType])}
      src={getIconSrc(iconType)}
      width={width}
      height={height}
      alt=""
      loading="lazy"
    />
  );
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label = '',
      placeholder = '',
      onChange = emptyFn,
      onBlur = emptyFn,
      value,
      checked,
      readOnly = false,
      onFocus = emptyFn,
      wrapperStyles,
      inputStyles,
      name,
      className,
      updatePairs,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const _onChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (updatePairs) {
        const { name: _name } = event.currentTarget;
        const { value: _value } = event.currentTarget;

        updatePairs({
          [_name]: _value,
        });
      }

      if (onChange) {
        onChange(event);
      }
    };

    const _onFocus = (event: React.FormEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    };

    const _onBlur = (event: React.FormEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    };

    return (
      <div
        id={`cb_${name}`}
        className={cx(
          styles['input-box'],
          isFocused ? styles.focused : '',
          className
        )}
        style={wrapperStyles}
      >
        <div className={cx(styles['input-wrapper'])}>
          <input
            id={`ip_${name}`}
            style={inputStyles}
            type="checkbox"
            onChange={_onChange}
            placeholder={placeholder}
            value={value}
            checked={checked}
            readOnly={readOnly}
            className={cx(
              label ? '' : styles.raw,
              styles[name],
              errorMessage ? styles.error : ''
            )}
            onFocus={_onFocus}
            onBlur={_onBlur}
            name={name}
            ref={ref}
            {...props}
          />
          <label
            className={errorMessage && styles.error}
            htmlFor={`ip_${name}`}
          >
            <Icon iconType={checked ? 'checked' : 'not-checked'} />
            {label}
          </label>
          {errorMessage && (
            <div className={styles['error-wrapper']}>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Checkbox;
