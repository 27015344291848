import { DEFAULT_PAGE_SIZE } from '../constants';
import { Order, PaymentTransaction } from '../types/order';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  count: DEFAULT_PAGE_SIZE,
  test: true,
};

export interface GetOrdersParams extends TestParams, PageParams {
  search?: string;
  statuses?: string;
  promotionCode?: string;
}

export const orderAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    orders: builder.query<ListResponse<Order>, GetOrdersParams>({
      query: (orderParams: GetOrdersParams) => {
        return {
          url: `/me/orders`,
          params: {
            ...defaultParams,
            ...orderParams,
          },
        };
      },
    }),
    order: builder.query<Order, string>({
      query: (orderId) => ({
        url: `/me/orders/${orderId}`,
      }),
    }),
    transactions: builder.query<PaymentTransaction[], string>({
      query: (orderId) => ({
        url: `/me/transactions?order=${orderId}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useOrdersQuery, useOrderQuery, useTransactionsQuery } = orderAPI;
