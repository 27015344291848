import { MERCHANT_API_BASE } from '../constants';
import { UpdatePromotionCodeParams } from '../redux/coupon';
import { MetadataParams, TestParams } from '../services/base';
import { DiscountType } from '../types/coupon';
import axios from './api';

export interface CouponParams extends TestParams {
  name: string;
  discountType: DiscountType;
  discountAmount?: number;
  currency?: string;
  discountPercentage?: number;
  maxRedemptionCount?: number;
  expiresAt?: number;
  active: boolean;
  metadata?: { [key: string]: number | string };
}

export type UpdateCouponParams = {
  id: string;
  active: boolean;
};

export interface CreatePromotionCodeParams extends TestParams, MetadataParams {
  coupon: string;
  code: string;
  active: boolean;
  expiresAt?: number;
  maxRedemptionCount?: number;
  firstTimeTransaction?: boolean;
  onePerCustomer: boolean;
  currency: string;
  minimumAmount?: number;
}

const defaultParameters = { metadata: {} };

const couponAPI = {
  createCoupon: (couponParams: CouponParams) => {
    const {
      discountPercentage,
      discountAmount,
      currency,
      metadata,
      ...restProps
    } = couponParams;

    const params: CouponParams = {
      ...defaultParameters,
      ...restProps,
      ...(discountPercentage && {
        discountPercentage,
      }),
      ...(discountAmount && { discountAmount }),
      ...(currency && { currency }),
      ...(metadata && { metadata }),
    };

    return axios.post(`${MERCHANT_API_BASE}/me/coupons`, params);
  },
  updateCoupon: (params: UpdateCouponParams) => {
    return axios.patch(`${MERCHANT_API_BASE}/me/coupons/${params.id}`, params);
  },
  updatePromotionCode: (params: UpdatePromotionCodeParams) => {
    const { promotionCodeId, ...restParams } = params;

    return axios.patch(
      `${MERCHANT_API_BASE}/me/promotion-codes/${promotionCodeId}`,
      restParams
    );
  },
  createPromotionCode: (
    createPromotionCodeParams: CreatePromotionCodeParams
  ) => {
    const { metadata, ...restProps } = createPromotionCodeParams;

    const params: CreatePromotionCodeParams = {
      ...defaultParameters,
      ...restProps,
      ...(metadata && { metadata }),
    };

    return axios.post(`${MERCHANT_API_BASE}/me/promotion-codes`, params);
  },
};

export default couponAPI;
