import { MERCHANT_API_BASE } from '../constants';
import { TestParams } from '../services/base';
import axios from './api';

export type PayoutParams = TestParams;

const defaultParams = {
  test: true,
};

const payoutAPI = {
  getPayouts: (payoutParams?: PayoutParams) =>
    axios.get(`${MERCHANT_API_BASE}/me/payouts`, {
      params: {
        ...defaultParams,
        ...payoutParams,
      },
    }),
};

export default payoutAPI;
