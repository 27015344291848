import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { API_BASE, DEFAULT_PAGE_SIZE } from '../constants';
import { Payout } from '../types/payout';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

export interface GetPayoutParams extends TestParams, PageParams {
  statuses?: string;
}

export const payoutAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    payouts: builder.query<ListResponse<Payout>, GetPayoutParams>({
      query: (params: GetPayoutParams) => {
        return {
          url: `/me/payouts`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    payout: builder.query<Payout, string>({
      query: (payoutId) => ({
        url: `/me/payouts/${payoutId}`,
      }),
    }),
    payoutReportDownloadLink: builder.query<{ downloadLink: string }, string>({
      query: (payoutId) => ({
        url: `${API_BASE}/reporting/payout-reports/${payoutId}/download-link/create`,
        method: 'POST',
      }),
      serializeQueryArgs: (args) => {
        // TTL of the link is 60mins, So change the cache key every 50mins
        return (
          defaultSerializeQueryArgs(args) +
          Math.floor(new Date().getTime() / 1000 / 60 / 50)
        );
      },
    }),
    payoutStatementDownloadLink: builder.query<
      { downloadLink: string },
      string
    >({
      query: (payoutId) => ({
        url: `${API_BASE}/reporting/payout-reports/${payoutId}/statement-download-link/create`,
        method: 'POST',
      }),
      serializeQueryArgs: (args) => {
        // TTL of the link is 60mins, So change the cache key every 50mins
        return (
          defaultSerializeQueryArgs(args) +
          Math.floor(new Date().getTime() / 1000 / 60 / 50)
        );
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePayoutsQuery,
  usePayoutQuery,
  usePayoutReportDownloadLinkQuery,
  useLazyPayoutReportDownloadLinkQuery,
  usePayoutStatementDownloadLinkQuery,
  useLazyPayoutStatementDownloadLinkQuery,
} = payoutAPI;
