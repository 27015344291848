import cx from 'classnames';
import { PayoutStatus } from '../../redux/payout';
import { OrderDisplayStatus } from '../../types/order';
import styles from './Capsule.module.scss';
import { OrderEventType } from '../../screens/OrderDetailScreen/utils';

export type CapsuleVariant =
  | 'default'
  | 'payment'
  | 'order'
  | 'mode'
  | 'sponsored'
  | 'invalid';
export type CapsuleColor =
  | 'gray'
  | 'dark-gray'
  | 'green'
  | 'tag-green'
  | 'cerise'
  | 'yellow'
  | 'orange'
  | 'blue';

export interface CapsuleProps {
  id?: string;
  title: string;
  variant?: CapsuleVariant;
  status?:
    | OrderDisplayStatus
    | PayoutStatus
    | OrderEventType
    | 'onhold'
    | undefined;
  color?: CapsuleColor;
  size?: 'large' | 'small';
  className?: string;
}

const getColor = (
  status: OrderDisplayStatus | PayoutStatus | OrderEventType | 'onhold'
): CapsuleColor => {
  switch (status) {
    case 'succeeded':
    case 'capture':
    case 'paid':
      return 'green';
    case 'partially_captured':
      return 'tag-green';
    case 'requires_capture':
    case 'onhold':
      return 'blue';
    case 'canceled':
      return 'dark-gray';
    default:
      return 'gray';
  }
};

const Capsule = ({
  id = '',
  title,
  variant = 'default',
  status = undefined,
  color = 'gray',
  size = 'large',
  className = '',
}: CapsuleProps) => {
  return (
    <i
      id={id}
      className={cx(
        styles.capsule,
        styles[variant],
        styles[color],
        styles[size],
        status && styles[getColor(status)],
        className
      )}
    >
      {title}
    </i>
  );
};

export default Capsule;
