import cx from 'classnames';
import styles from './Popup.module.scss';
import { useLayoutEffect, useRef, useState } from 'react';

export type PopupDirection = 'down' | 'up';

const POPUP_SIZE = 80;

const Popup = ({
  content,
  trigger,
  direction = 'down',
  responsive = true,
  className = '',
}: {
  content: JSX.Element;
  trigger: JSX.Element;
  direction?: PopupDirection;
  responsive?: boolean;
  className?: string;
}) => {
  const [responsiveDirection, setResponsiveDirection] = useState(direction);
  const nodeRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (responsive && nodeRef.current) {
      const rect = nodeRef.current.getBoundingClientRect();

      if (direction === 'up') {
        setResponsiveDirection(rect.top < POPUP_SIZE ? 'down' : 'up');
      } else if (direction === 'down') {
        setResponsiveDirection(
          window.innerHeight - rect.bottom < POPUP_SIZE ? 'up' : 'down'
        );
      }
    }
  }, [responsive, direction]);

  return (
    <div ref={nodeRef} className={cx(styles.trigger, className)}>
      <div className={cx(styles.popup, styles[responsiveDirection])}>
        <div className={styles.inner}>{content}</div>
      </div>
      <div className={cx(styles['trigger-item'], styles[responsiveDirection])}>
        {trigger}
        <span
          className={cx(styles['arrow-border'], styles[responsiveDirection])}
        />
        <span className={cx(styles.arrow, styles[responsiveDirection])} />
      </div>
    </div>
  );
};

export default Popup;
