import { useTranslation } from 'react-i18next';
import zenginCode from '../../data/zenjin-code.json';
import { Payout } from '../../types/payout';

const anyPad = (num: number | string, places: number, pathWith: string) =>
  String(num).padStart(places, pathWith);

const getDisplayedBank = (bankCode: number, locale = 'ja') => {
  const paddedBankCode = anyPad(bankCode, 4, '0');
  const nameProp = locale.startsWith('ja') ? 'name' : 'roma';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const bank = zenginCode[paddedBankCode]?.[nameProp];

  return bank ? `${bank} (${paddedBankCode})` : paddedBankCode;
};

const getDisplayedBranch = (
  bankCode: number,
  branchCode: number,
  locale = 'ja'
) => {
  const paddedBankCode = anyPad(bankCode, 4, '0');
  const paddedBranchCode = anyPad(branchCode, 3, '0');
  const nameProp = locale.startsWith('ja') ? 'name' : 'roma';
  const branch =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    zenginCode[paddedBankCode]?.branches[paddedBranchCode]?.[nameProp];

  return branch ? `${branch} (${paddedBranchCode})` : paddedBranchCode;
};

type BankMeta = {
  bank: string;
  branch: string;
  account: string;
};

const useBankMeta = ({ payout }: { payout?: Payout }): BankMeta | undefined => {
  const { i18n } = useTranslation();

  const firstActiveBankAccount = payout?.bankAccounts?.find(
    (account) => account.status === 'active'
  );

  if (!firstActiveBankAccount) {
    return undefined;
  }

  const { bankCode, branchCode, accountNumber } = firstActiveBankAccount;

  return {
    bank: getDisplayedBank(bankCode, i18n.language),
    branch: getDisplayedBranch(bankCode, branchCode, i18n.language),
    account: anyPad(accountNumber.slice(-4), accountNumber.length - 4, '•'),
  };
};

export default useBankMeta;
