import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentAPI from '../../api/payment-link';
import Button from '../../components/Form/Button';
import LabeledComponent from '../../components/Form/LabeledComponent';
import RadioButton from '../../components/Form/RadioButton';
import TextInput from '../../components/Form/TextInput';
import { PAYMENT_LINK_CREATION_SCREEN } from '../../constants';
import type { LineItemReq, OnCompletion } from '../../types/payment-link';
import { currencyFormatter, isURL } from '../../utils';
import createEventHandler from '../../utils/create-event-handler';
import { useDataMode } from '../../utils/helper';
import styles from './ModalCreate.module.scss';

const emptyFn = () => {};

const Form = ({
  onSuccess,
  onDismiss,
  isModal = true,
}: {
  onSuccess: Function;
  onDismiss: Function;
  isModal?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const { test: isTest } = useDataMode();

  const [redirectURL, setRedirectURL] = useState('');
  const [redirectURLError, setRedirectURLError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmApproach, setConfirmApproach] = useState<'hosted' | 'redirect'>(
    'hosted'
  );
  const [lineItems, setLineItems]: [LineItemReq[], Function] = useState([]);

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [description, setDescription] = useState('');

  const [nameError, setNameError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [quantityError, setQuantityError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setConfirmMessage('');
    setRedirectURL('');
  }, [confirmApproach]);

  const onAddItem = () => {
    const items = [...lineItems];

    items.push({
      name: name.trim(),
      amount,
      quantity,
      description: description.trim(),
      currency: 'JPY',
    });

    setLineItems(items);
    setName('');
    setAmount(0);
    setQuantity(0);
    setDescription('');
  };

  const onRemoveItem = (idx: number) => {
    setLineItems(lineItems.slice(0, idx).concat(lineItems.slice(idx + 1)));
  };

  const canSubmit = (): boolean => {
    if (!lineItems.length) {
      return false;
    }

    if (
      confirmApproach === 'redirect' &&
      (!redirectURL.trim() || !isURL(redirectURL.trim()))
    ) {
      return false;
    }

    return true;
  };

  const onSubmit = createEventHandler(async () => {
    if (isLoading || !canSubmit()) {
      return;
    }

    setIsLoading(true);

    Mixpanel.trackAction({
      screen: PAYMENT_LINK_CREATION_SCREEN,
      action: 'Click',
      itemName: 'Create Payment Link - Submit',
    });

    const getOnCompletion = (): OnCompletion => {
      const onCompletion: {
        type: 'redirect' | 'hosted';
        confirmationMessage?: string;
        url?: string;
      } = { type: confirmApproach };

      if (confirmApproach === 'hosted') {
        if (confirmMessage) {
          onCompletion.confirmationMessage = confirmMessage.trim();
        }
      } else if (confirmApproach === 'redirect') {
        onCompletion.url = redirectURL.trim();
      }

      return onCompletion;
    };

    try {
      await PaymentAPI.createLink({
        payload: {
          items: lineItems,
          test: isTest,
          onCompletion: getOnCompletion(),
        },
      });

      onSuccess();
    } catch (err) {
      // TODO: display the error message somewhere
      setIsLoading(false);

      return;
    }

    onDismiss();
  });

  const ModalFooter = () => {
    return (
      <div className={styles['modal-footer']}>
        <Button
          id="btn_create_payment_link_cancel"
          label={t('payment-link.action.cancel')}
          size="small"
          variant="outline"
          onClick={() => {
            Mixpanel.trackAction({
              screen: PAYMENT_LINK_CREATION_SCREEN,
              action: 'Click',
              itemName: 'Create Payment Link - Cancel',
            });

            onDismiss();
          }}
        />
        <Button
          id="btn_create_payment_link_submit"
          label={t('payment-link.action.create')}
          size="small"
          disabled={!canSubmit()}
          onClick={onSubmit}
          processing={isLoading}
        />
      </div>
    );
  };

  return (
    <form
      autoComplete="off"
      className={cx(styles.modal, isTest ? styles.test : null)}
    >
      <div>
        <div className={styles.heading}>
          <h3>
            {isTest
              ? t('payment-link.action.create-test-payment-link')
              : t('payment-link.action.create-payment-link')}
          </h3>
          <div className={styles.subtotal}>
            {t('payment-link.modal.total-amount')}{' '}
            <span className={styles['incl-tax']}>
              {t('payment-link.modal.incl-tax')}
            </span>
            <span className={styles.amount}>
              ¥
              {currencyFormatter(
                lineItems.reduce((accu, item) => {
                  let _accu = accu;

                  return (_accu += item.amount * item.quantity);
                }, 0)
              )}
            </span>
          </div>
        </div>
        <p>Add items to this payment link</p>
      </div>
      <div className={styles['form-body']}>
        <fieldset>
          <TextInput
            autoFocus
            name="name"
            label={t('payment-link.modal.name')}
            placeholder="Name of this item"
            value={name}
            onChange={(event) => {
              setName(event.currentTarget.value);

              if (name !== '') {
                setNameError('');
              }
            }}
            onBlur={(event) => {
              if (event.currentTarget.value.trim() === '') {
                setName('');
                setNameError('Name is required.');
              }
            }}
            errorMessage={nameError}
          />
          <TextInput
            name="description"
            label={t('payment-link.modal.description')}
            placeholder="Description of this item"
            value={description}
            onChange={(event) => {
              setDescription(event.currentTarget.value);

              if (descriptionError !== '') {
                setDescriptionError('');
              }
            }}
            onBlur={(event) => {
              if (event.currentTarget.value.trim() === '') {
                setDescription('');
                setDescriptionError('Description is required.');
              }
            }}
            errorMessage={descriptionError}
          />
          <TextInput
            name="quantity"
            type="number"
            label={t('payment-link.modal.quantity')}
            inputStyles={{ minWidth: 0 }}
            placeholder=""
            value={`${quantity}`}
            showErrorWithoutMessage
            onChange={(event) => {
              setQuantity(+event.currentTarget.value);

              if (quantityError !== '') {
                setQuantityError('');
              }
            }}
            onBlur={(event) => {
              if (!+event.currentTarget.value.trim()) {
                setQuantityError('Quantity is required.');
              }
            }}
            errorMessage={quantityError}
          />
          <TextInput
            name="amount"
            type="number"
            label={t('payment-link.modal.amount')}
            placeholder=""
            value={`${amount}`}
            showErrorWithoutMessage
            onChange={(event) => {
              setAmount(+event.currentTarget.value);

              if (amountError !== '') {
                setAmountError('');
              }
            }}
            onBlur={(event) => {
              if (!+event.currentTarget.value.trim()) {
                setAmountError('Amount is required.');
              }
            }}
            errorMessage={amountError}
          />
          <LabeledComponent
            label="&nbsp;"
            labelSpace="small"
            className={styles['btn-add-box']}
          >
            <Button
              id="btn_create_payment_link_add_item"
              label="+"
              disabled={!(amount && name && description && quantity)}
              onClick={() => {
                Mixpanel.trackAction({
                  screen: PAYMENT_LINK_CREATION_SCREEN,
                  action: 'Click',
                  itemName: 'Create Payment Link - Add Item',
                });

                onAddItem();
              }}
              className={styles['btn-add']}
            />
          </LabeledComponent>
        </fieldset>
        {lineItems.map((item, i) => {
          return (
            <fieldset className={styles.items} key={i}>
              <div className={styles.display}>{item.name}</div>
              <div className={styles.display}>{item.description}</div>
              <div className={styles.display}>{item.quantity}</div>
              <div className={cx(styles.display, styles['no-wrap'])}>
                ¥ {currencyFormatter(item.amount)}
              </div>
              <Button
                id="btn_create_payment_link_remove_item"
                label="-"
                variant="outline"
                onClick={() => {
                  Mixpanel.trackAction({
                    screen: PAYMENT_LINK_CREATION_SCREEN,
                    action: 'Click',
                    itemName: 'Create Payment Link - Remove Item',
                  });

                  onRemoveItem(i);
                }}
                className={styles['btn-remove']}
              />
            </fieldset>
          );
        })}
        <div className={styles.options}>
          <h2>Confirmation page</h2>
          <div className={styles.radios}>
            <RadioButton
              id="radio_confirm_approach_hosted"
              title={t('payment-link.modal.hosted')}
              checked={confirmApproach === 'hosted'}
              onClick={() => {
                setConfirmApproach('hosted');
              }}
            />
            <RadioButton
              id="radio_confirm_approach_redirect"
              title={t('payment-link.modal.redirect')}
              checked={confirmApproach === 'redirect'}
              onClick={() => {
                setConfirmApproach('redirect');
              }}
            />
          </div>
          <div className={styles['extra-field']}>
            {confirmApproach === 'hosted' && (
              <TextInput
                name="confirmation_message"
                label={`${t('payment-link.modal.custom-message')} ${t(
                  'payment-link.modal.optional'
                )}`}
                placeholder={t('payment-link.modal.custom-message-placeholder')}
                value={confirmMessage}
                onChange={(event) => {
                  setConfirmMessage(event.currentTarget.value);
                }}
              />
            )}
            {confirmApproach === 'redirect' && (
              <TextInput
                name="redirect_url"
                label={`${t('payment-link.modal.redirect-url')} ${t(
                  'payment-link.modal.required'
                )}`}
                placeholder={t('payment-link.modal.redirect-url-placeholder')}
                value={redirectURL}
                onChange={(event) => {
                  setRedirectURL(event.currentTarget.value);

                  if (redirectURLError !== '') {
                    setRedirectURLError('');
                  }
                }}
                onBlur={(event) => {
                  if (event.currentTarget.value.trim() === '') {
                    setRedirectURLError('URL is required.');
                  } else if (!isURL(event.currentTarget.value.trim())) {
                    setRedirectURLError('URL is malformed.');
                  }
                }}
                errorMessage={redirectURLError}
              />
            )}
          </div>
        </div>
      </div>
      {isModal && <ModalFooter />}
    </form>
  );
};

const ModalCreate = ({
  onSuccess = emptyFn,
  onDismiss,
}: {
  onSuccess?: () => void;
  onDismiss: () => void;
}) => {
  return (
    <div className={styles['modal-background']}>
      <Form onSuccess={onSuccess} onDismiss={onDismiss} />
    </div>
  );
};

export default ModalCreate;
