import Mixpanel from '@smartpay/mixpanel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Form/Button';
import styles from './PaginationView.module.scss';

const PaginationView = ({
  onPrevClick,
  onNextClick,
  hasNextPage,
  hasPrevPage,
  screen,
}: {
  onPrevClick: () => void;
  onNextClick: () => void;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  screen: string;
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className={styles['pagination-wrapper']}>
      <Button
        id="btn_prev"
        label={t('prev-btn')}
        variant="outline"
        size="small"
        onClick={() => {
          Mixpanel.trackAction({
            screen,
            action: 'Click',
            itemName: 'Prev',
          });

          onPrevClick();
        }}
        disabled={!hasPrevPage}
      />
      <Button
        id="btn_next"
        label={t('next-btn')}
        variant="outline"
        size="small"
        onClick={() => {
          Mixpanel.trackAction({
            screen,
            action: 'Click',
            itemName: 'Next',
          });

          onNextClick();
        }}
        disabled={!hasNextPage}
      />
    </div>
  );
};

export default PaginationView;
