import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import orderAPI from '../api/order';
import { ErrorDetail } from './auth';
import { ERROR_UNEXPECTED_ERROR } from './error-codes';

const initialState: {} = {};

type CancelOrderResponse = {};

type GeneralAsyncThunkConfig = {
  rejectValue: {
    errorCode: string;
    message?: string;
    details?: ErrorDetail[];
  };
};

export const cancelOrder = createAsyncThunk<
  CancelOrderResponse,
  string,
  GeneralAsyncThunkConfig
>('orders/cancelOrder', async (orderId: string, { rejectWithValue }) => {
  try {
    const response = await orderAPI.cancelOrder(orderId);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { payload } = error.response.data;

      switch (payload.errorCode) {
        case ERROR_UNEXPECTED_ERROR: {
          return rejectWithValue(payload);
        }
        default: {
          break;
        }
      }
    }

    return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
  }
});

const settingSlice = createSlice({
  name: '@@merchant-dashboard/cancel-order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cancelOrder.fulfilled, () => {});
  },
});

export default settingSlice.reducer;
